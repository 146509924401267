import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { router } from "../router/Routes";
import { store } from "./store";
import {  AuthErrorCodes, GoogleAuthProvider, getAuth, signInWithEmailAndPassword} from "firebase/auth";
import { app } from "../../firestore-config";
import { toast } from 'react-toastify';
import { User } from "../models/company";

export default class UserStore {
    user: User | null = null;
    constructor() {
        makeAutoObservable(this)
    }
    get isLoggedIn() {
        return !!this.user;
    }
    login = async (creds: any) => {
        try {
            const auth = getAuth(app);            
            signInWithEmailAndPassword(auth, creds.email, creds.password)
      .then((userCredential) => {
        // Signed in        
        store.commonStore.setToken(userCredential.user.refreshToken);
        const newuser:User = 
         {
                uid: userCredential.user.uid,
                name: userCredential.user.displayName!,
             token: userCredential.user.refreshToken,
             email: userCredential.user.email!,             
             authProvider: "email",
             active:false,
             photoUrl:userCredential.user.photoURL,
             actionDate:new Date(),
             actionUser: "",
             id:"",
             userType:"Visitor",
             status:"Pending"
         };
         runInAction(() => this.user = newuser);
            router.navigate('/dashboard');            
      })
      .catch((err) => {
        if (
        err.code === AuthErrorCodes.INVALID_PASSWORD ||
        err.code === AuthErrorCodes.USER_DELETED
      ) {
        toast.error('The email address or password is incorrect');        
      } else {
        console.log(err.code);
        alert(err.code);
      }
      });   
        } catch (error) {
            throw error;
        }
    }

    register = async (creds: User) => {
        try {
            //const user = await agent.Account.register(creds);
            // store.commonStore.setToken(user.token);
            //runInAction(() => this.user = user);
            router.navigate('/dashboard');
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    setUser = async (user : User) =>{
        try {
            runInAction(() =>{
                store.commonStore.setToken(user.token);
                this.user = user;
                console.log(user);
            });            
        } catch (error) {
            throw error;
        } 
    }
    // enquiry = async (enquiry: EnquiryFormValues) => {
    //     try {
    //         const user = await agent.Account.register(enquiry);
    //         store.commonStore.setToken(user.token);
    //         runInAction(() => this.user = user);
    //         router.navigate('/activities');
    //         store.modalStore.closeModal();
    //     } catch (error) {
    //         throw error;
    //     }
    // }


    logout = () => {
        runInAction(()=>{
            store.commonStore.setToken(null);
            this.user = null;
        });
        router.navigate('/');
    }

    getUser = async () => {
        try {            
            if(store.commonStore.token !== null && 
                store.commonStore.token !== undefined)
                {
                    const auth = getAuth(app);
                    let newuser:any;
                    if(auth.currentUser !== null && 
                        auth.currentUser !== undefined)
                        {
                            this.setUser(new User(
                                {
                                    uid: auth.currentUser!.uid,
                                    name: auth.currentUser!.displayName!,
                                    token: auth.currentUser!.refreshToken,
                                    email: auth.currentUser!.email!,             
                                    authProvider: "email",
                                    active:false,
                                    photoUrl:  auth.currentUser!.photoURL,
                                    actionDate: new Date(),
                                    actionUser:"",
                                    id:"",
                                    userType:"Visitor",
                                    status:"Pending"
                                }));
                            
                        }else
                        {                            
                            auth.onAuthStateChanged((user:any)=> {
                                console.log(user);
                                this.setUser(new User( 
                                    {
                                        uid: user.uid,
                                        name: user.displayName!,
                                        token: user.refreshToken,
                                        email: user.email!,             
                                        authProvider: "email",
                                        active:false,
                                        photoUrl:  user.photoURL,
                                        actionDate:new Date(),
                                        actionUser:"",
                                        id:"",
                                        userType:"Visitor",
                                        status:"Pending"
                                    }));
                            })                            
                        }
                }
            return this.user;
        } catch (error) {
            console.log(error);
        }
    }

    setImage = (image: string) => {
        // if (this.user) this.user.image = image;
    }

    setUserPhoto = (url: string) => {
        // if (this.user) this.user.image = url;
    }

    setDisplayName = (name: string) => {
        // if (this.user) this.user.displayName = name;
    }    
}