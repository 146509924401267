import React from 'react';
import {useField} from "formik";
import {Form, Label} from "semantic-ui-react";

interface Props {
    placeholder: string;
    name: string;
    label?: string;
    type?: string;
    className?:string;
    labelclassname?:string;   
    pattern?:string;
    errorclassname?:string;
    divclassname?:string;
    maxlength?:string;
    min?:string;
}

export default function MyTextInput(props: Props) {
    const [field, meta] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <div className={props.divclassname}>
            {props.label !== null && props.label !== undefined && props.label !== ""  &&             
            <label className={props.labelclassname}>{props.label}</label>
            }
            <input {...field} {...props}/>
            {meta.touched && meta.error ? (
                <div data-form-alert-danger="" className={props.errorclassname}> {meta.error}
            </div>                
            ) : null}
            </div>
        </Form.Field>
    )
}
