import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import ManageActionsControls from "../admin/controls/ManageActionsControls";
import { ErrorMessage, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import ValidationError from "../errors/ValidationError";
import { Button, Form } from "semantic-ui-react";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { parametertype, wizardstatus, wizardtype } from "../../app/common/options/categoryOptions";
import { useNavigate, useParams } from "react-router-dom";
import GridSystem, { Item } from "../../app/common/controls/GridSystem";
import { CustomControl } from "../../app/models/customcontrols";

export default observer(function AddCustomControl() {
    const { userStore,customControlStore} = useStore();
    const navigate = useNavigate();
    const [result, setResult] = useState<Boolean>(false);    
    const { id } = useParams();
    const {customControl,setCustomControl,
        loadCustomControl,clearCustomControl} = customControlStore;        
    useEffect(() => {
        if (id) loadCustomControl(id).then(param => setCustomControl(new CustomControl(param)))
    }, [id, loadCustomControl])
    return (
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">              
        <div className="row justify-content-center">
            <div className="card_item col-12 col-lg-4 col-md-4">
                <div className="item-wrapper">
                    <div className="card-box">
                        <div className="content_box">
                            <div className="square_animation">
                                <div className="square_null square"></div>
                                <div className="square_fill square"></div>
                            </div>
                            <h4 className="card-title ls-1 mbr-fonts-style display-5">Create Custom Control</h4>
                            <p className="card-text mb-0 mbr-fonts-style display-4">
                            Please provide essential custom control
                            </p>                            
                        </div>                        
                        <div className="row">            
                                        <div className="col-12 col-lg-12 card">
                                            <div className="mbr-form form-wrapper" data-form-type="formoid">
                    <Formik  enableReinitialize
                                            initialValues={customControl}
                            onSubmit={(values, { setErrors }) =>
                            {   
                                setCustomControl(values);
                                setResult(true);
                            }
                            }
                    >
                        {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (                
                            <Form className="mbr-form form-with-styler form-wrap" 
                            onSubmit={handleSubmit} 
                            autoComplete='off'>
                                <div className="dragArea row">
                                <MyTextInput placeholder="keyName" name='keyName' 
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                                className="form-control display-4"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Parameter key Name" errorclassname="alert alert-danger col-12" />
                                <MyTextInput placeholder="Parameter keyValue" name='keyValue' 
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                className="form-control display-7"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Parameter keyValue" errorclassname="alert alert-danger col-12"
                                />
                                <MyTextInput placeholder="Display Order" name='seq' type="number"                     
                                className="form-control display-7" divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Display Order" errorclassname="alert alert-danger col-12" min="1"
                                />
                                <MySelectInput options={parametertype} name='parameterType' placeholder='Parameter Type'
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                className="form-control display-4"
                                label="Parameter Type" errorclassname="alert alert-danger col-12"
                                />                                
                                    <div className="col-12 col-md-auto mbr-section-btn">
                                    <Button
                                    disabled={!isValid || !dirty || isSubmitting} 
                                    loading={isSubmitting} 
                                    positive content='Submit Parameter' 
                                    type="submit" fluid
                                    className="btn btn-info display-4"
                                />                            
                                    </div>                                               
                                </div>
                                {result && <div data-form-alert="" className="alert alert-success col-12">Information Saved</div>} 
                            </Form>
                        )}

                    </Formik>
                                            </div>
                                        </div>
                                    </div>                    
                    </div>
                </div>
           </div>
           
       <div className="card_item col-12 col-lg-8 col-md-8">
       <section data-bs-version="5.1" className="content9 visualm5 cid-tZqIByRrCi" id="content9-1q">
    <div className="container-fluid">
        <div className="row">            
            <div className="col-12 col-lg-12">
                <div className="text-wrapper">
                    <p className="mbr-text mbr-fonts-style display-5">
                    Ability to setup Custom Control
                    </p>
                </div>
            </div>            
            <div className="col-lg-12">
                <div id="bootstrap-accordion_38" className="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_39" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Child Custom Control?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_39" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#collapse4_39" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
                                <div className="container">		
                                    <>		
                                    <div className="row">		
                                        <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Add Child Custom Control</strong></h5>
                                                    {/* <p className="price mbr-fonts-style display-7">₹5,000 to ₹20,000 INR</p> */}
                                                    <p className="card-text mbr-fonts-style display-7">
                                                    Ability to create child Custom Control
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7" 						
                                                    onClick={()=> {                                                        
                                                        navigate(`/addcontrol/${customControl.id}`);
                                                    }}
                                                    >Add Parameter Custom Control</Button></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Manage child Custom Control</strong></h5>
                                                    
                                                    <p className="card-text mbr-fonts-style display-7">Ability to Add/Manage child Custom Control
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7"
                                                        onClick={()=>{
                                                            navigate(`/controllist/${customControl.id}`)
                                                            }}>Manage Child Custom Control
                                                        </Button></div>
                                                </div>
                                            </div>
                                        </div>			
                                    </div>
                                    </>
                                </div>
                            </section>                                
                                {/* <ManageActionsControls addactionRoute="/add" addactiontext="Add Parameters" 
                                    addbuttontext="Create Parameter" adddescription="Ability to create parameter"
                                    mangeactionRoute="/manage" mangeactiontext="Manage Parameters"
                                    mangebuttontext="Manage" mangedescription="Ability to Add/Manage Parameters"  /> */}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingThree">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse3_38" aria-expanded="false" aria-controls="collapse3">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                How to Manage Child Controls?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse3_38" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
                                <div className="container">		
                                    <>		
                                    <div className="row">		
                                        <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Add Child Custom Control</strong></h5>
                                                    {/* <p className="price mbr-fonts-style display-7">₹5,000 to ₹20,000 INR</p> */}
                                                    <p className="card-text mbr-fonts-style display-7">
                                                    Ability to create child Custom Control
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7" 						
                                                    onClick={()=> {                                                        
                                                        navigate(`/addcontrol/${customControl.id}`);
                                                    }}
                                                    >Add Parameter Custom Control</Button></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Manage child Custom Control</strong></h5>
                                                    
                                                    <p className="card-text mbr-fonts-style display-7">Ability to Add/Manage child Custom Control
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7"
                                                        onClick={()=>{
                                                            navigate(`/controllist/${customControl.id}`)
                                                            }}>Manage Child Custom Control
                                                        </Button></div>
                                                </div>
                                            </div>
                                        </div>			
                                    </div>
                                    </>
                                </div>
                            </section>                                
                                {/* <ManageActionsControls addactionRoute="/addchildcontrol" addactiontext="Add Child Control" 
                                    addbuttontext="Create Child Control" adddescription="Ability to create child Control"
                                    mangeactionRoute="/childcontrollist" mangeactiontext="Manage Child Control"
                                    mangebuttontext="Manage" mangedescription="Ability to Add/Manage Control"  /> */}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_41" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Conditions?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_41" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_38">
                         <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
                                <div className="container">		
                                    <>		
                                    <div className="row">		
                                    <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Manage Display Conditions</strong></h5>
                                                    
                                                    <p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Display Condition
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7"
                                                        onClick={()=>{                                                            
                                                            navigate("/displaycondition")}}>Manage
                                                        </Button></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Manage Disabled Conditions</strong></h5>
                                                    
                                                    <p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Disabled Condition
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7"
                                                        onClick={()=>{                                                            
                                                            navigate("/disabledcondition")}}>Manage
                                                        </Button></div>
                                                </div>
                                            </div>
                                        </div>                                        			
                                    </div>                                    
                                    </>
                                </div>
                            </section>
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
                                <div className="container">		
                                    <>		
                                    <div className="row">
                                        <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Manage Required Condition</strong></h5>
                                                    
                                                    <p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Required Condition
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7"
                                                        onClick={()=>{                                
                                                            navigate("/requiredcondition")}}>Manage Required Condition
                                                        </Button></div>
                                                </div>
                                            </div>
                                        </div>			
                                    </div>
                                    </>
                                </div>
                            </section>                         
                         </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
           </div>            
           </div>
    </div>
</section>
    )
})