export interface Parameter {
    id:string;
    keyName: string;
    keyValue: string;
    createDate:Date;
    createBy:string;
    updateDate:Date;
    updateBy:string;
}

export class Parameter implements Parameter {
    constructor(param?:Parameter)
    {
        if(param)
        {
            Object.assign(this,param);
        }
    }
    id:string = "";
    keyName: string = "";
    keyValue: string = "";
    createDate:Date = new Date();
    createBy:string = "";
    updateDate:Date = new Date();
    updateBy:string = "";
}
