export interface ConditionConfig {
    id:string;
    leftExpression: string;
    rightExpression: string;
    operation: string;
    conditionType: string;
    message: string;
    createDate:Date;
    createBy:string;
    updateDate:Date;
    updateBy:string;
    seq: number;
}
export class ConditionConfig implements ConditionConfig {
    constructor(condition?:ConditionConfig)
    {
        if(condition)
        {
            Object.assign(this,condition);
        }
    }
    id:string = "";
    leftExpression: string="";
    rightExpression: string="";
    operation: string="";
    conditionType: string="";
    message: string="";
    createDate:Date = new Date();
    createBy:string = "";
    updateDate:Date = new Date();
    updateBy:string = "";
    seq: number = 1;
}