import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { ErrorMessage, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import { Button, Form } from "semantic-ui-react";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { conditiontype, operationtype, planstatus, plantype } from "../../app/common/options/categoryOptions";
import { useNavigate, useParams } from "react-router-dom";

export default observer(function ManagePlanPage() {
    const { userStore,planStore,companyStore} = useStore();
    const [result, setResult] = useState<Boolean>(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const {loadPlan,plan,
        setPlan,planByDate,
        setCompanyPlan} = planStore;
        const {company} = companyStore;
    useEffect(() => {
        if (id) loadPlan(id)
    }, [id, loadPlan])
    return (
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">              
        <div className="row justify-content-center">
            <div className="card_item col-12 col-lg-4 col-md-4">
                <div className="item-wrapper">
                    <div className="card-box">
                        <div className="content_box">
                            <div className="square_animation">
                                <div className="square_null square"></div>
                                <div className="square_fill square"></div>
                            </div>
                            <h4 className="card-title ls-1 mbr-fonts-style display-5">Manage Plan</h4>
                            <p className="card-text mb-0 mbr-fonts-style display-4">
                            Please provide essential plan
                            </p>                            
                        </div>                        
                        <div className="row">            
                                        <div className="col-12 col-lg-12 card">
                                            <div className="mbr-form form-wrapper" data-form-type="formoid">
                    <Formik  enableReinitialize
                                            initialValues={plan}
                            onSubmit={(values, { setErrors }) =>
                            {
                                setPlan(values);
                                setResult(true);
                            }
                            }
                    >
                        {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (                
                            <Form className="mbr-form form-with-styler form-wrap" 
                            onSubmit={handleSubmit} 
                            autoComplete='off'>
                                <div className="dragArea row">
                                <MyTextInput placeholder="Plan Name" name='name' 
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                                className="form-control display-4"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Plan Name" errorclassname="alert alert-danger col-12" />
                                <MyTextInput placeholder="Plan Title" name='title' 
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                                className="form-control display-4"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Plan Title" errorclassname="alert alert-danger col-12" />
                                <MyTextInput placeholder="Plan Detail" name='details' 
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                                className="form-control display-4"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Plan Detail" errorclassname="alert alert-danger col-12" />
                                <MyTextInput placeholder="Plan Description" name='description' 
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                                className="form-control display-4"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Plan Description" errorclassname="alert alert-danger col-12" />                                
                                <MySelectInput options={plantype} name='planType' placeholder='Plan Type'
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                className="form-control display-4"
                                label="Plan Type" errorclassname="alert alert-danger col-12"
                                />
                                <MyTextInput placeholder="Entries" name='entries' type="number"                     
                                className="form-control display-7" divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Entries" errorclassname="alert alert-danger col-12" min="1"
                                /> 
                                <MyTextInput placeholder="Renew Date" name='renewDate' type="date"
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                                className="form-control display-4"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Renew Date" errorclassname="alert alert-danger col-12" />                                
                                <MySelectInput options={planstatus} name='currentStatus' placeholder='Plan Status'
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                className="form-control display-4"
                                label="Plan Status" errorclassname="alert alert-danger col-12"
                                />                                                                                               
                                    <div className="col-12 col-md-auto mbr-section-btn">
                                    <Button
                                    disabled={!isValid || !dirty || isSubmitting} 
                                    loading={isSubmitting} 
                                    positive content='Submit Plan' 
                                    type="submit" fluid
                                    className="btn btn-info display-4"
                                />                            
                                    </div>                                               
                                </div>
                                {result && <div data-form-alert="" className="alert alert-success col-12">Information Saved</div>} 
                            </Form>
                        )}
                    </Formik>
                                            </div>
                                        </div>
                                    </div>                    
                    </div>
                </div>
           </div>
           
       <div className="card_item col-12 col-lg-8 col-md-8">
       <section data-bs-version="5.1" className="content9 visualm5 cid-tZqIByRrCi" id="content9-1q">
    <div className="container-fluid">
        <div className="row">            
            <div className="col-12 col-lg-12">
            <p className="mbr-text mbr-fonts-style display-5">
                    Ability to manage {company.name} plan
                    </p>
            </div>            
            <div className="col-lg-12">
            <div className="row justify-content-center">
        <div className="col-md-auto col mbr-section-btn">
        <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate(`/addcompany/${company.id}`)}>
                                Back to Company
                                </button>
        <button type="button" className="btn btn-secondary display-5" 
        onClick={()=> {
            setCompanyPlan();
        }}>                                
                                Add to Company
                                </button>
            {/* <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>                                
                                Add Child Wizard</button> */}
                                </div>        
        <table className="table item-wrapper">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Id</th>
      <th scope="col">Name</th>
      <th scope="col">Title</th>
      <th scope="col">Details</th>
      <th scope="col">Plan Type</th>
      <th scope="col">Entry Count</th>          
      <th scope="col">Action Date</th>
      <th scope="col">Renew Date</th>
      <th scope="col">Status</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
  {planByDate.map((plan, index) => (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{plan.id}</td>
      <td>{plan.name}</td>
      <td>{plan.title}</td>
      <td>{plan.details}</td>
      <td>{plan.planType}</td>
      <td>{plan.entries}</td>
      <td>{plan.actionDate.toISOString()}</td>
      <td>{plan.renewDate.toISOString()}</td>
      <td>{plan.currentStatus}</td>
      <td></td>
    </tr>
))}
  </tbody>
</table>
           </div>                
            </div>
        </div>
    </div>
</section>
           </div>            
           </div>
    </div>
</section>
    )
})