import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import ManageActionsControls from "../admin/controls/ManageActionsControls";
import { ErrorMessage, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import ValidationError from "../errors/ValidationError";
import { Button, Form } from "semantic-ui-react";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { wizardstatus, wizardtype } from "../../app/common/options/categoryOptions";
import { useNavigate, useParams } from "react-router-dom";
import { Wizard } from "../../app/models/wizard";
import GridSystem, { Item } from "../../app/common/controls/GridSystem";
import { el } from "date-fns/locale";

export default observer(function AddWizard() {
    const { userStore,wizardStore,standardConfigurationStore,standardParameterStore,displayConditionStore } = useStore();
    const navigate = useNavigate();
    const [result, setResult] = useState<Boolean>(false);
    const { id } = useParams();
    const {wizard,setWizard,setChildWizard,loadWizard} = wizardStore;
    useEffect(() => {
        if (id)
        {
            loadWizard(id).then(wizard => setWizard(wizard!))
            setChildWizard(true);            
        }else
        {
            setWizard(new Wizard())
            setChildWizard(false);
        }
    },[])    
    const jsonFileDownload = () => {        
        const fileName = "filename.json";
        //const data = new Blob([JSON.stringify(json_data)], { type: "text/json" });
        const data = new Blob([JSON.stringify(standardConfigurationStore.standardConfiguration)], { type: "text/json" });
        const jsonURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = jsonURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
      };
    // const albums = [
    //     {
    //       id: 0,
    //       title: 'Album One',
    //       artist: 'Alex'
    //     },
    //     {
    //       id: 1,
    //       title: 'Album Two',
    //       artist: 'Percy'
    //     },
    //     {
    //       id: 2,
    //       title: 'Album Three',
    //       artist: 'Kevin'
    //     },
    //     {
    //       id: 3,
    //       title: 'Album Four',
    //       artist: 'John'
    //     },
    //     {
    //       id: 4,
    //       title: 'Album Five',
    //       artist: 'Stacy'
    //     }
    //   ]      
    // useEffect(() => {
    //     if (id) loadActivity(id).then(activity => setActivity(new ActivityFormValues(activity)))
    // }, [id, loadActivity])
    return (        
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">
        {/* <div className="main_header">
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-12 align-center">
                    
                    <h3 className="mbr-section-title mb-0 mbr-fonts-style ls-1 display-1">Let's talk about it</h3>
                    
                </div>
            </div>
        </div> */}
        <button
  type="button" onClick={()=> jsonFileDownload()}
  >
 Download Json
 </button>        
        <div className="row justify-content-center">
            <div className="card_item col-12 col-lg-4 col-md-4">
                <div className="item-wrapper">
                    <div className="card-box">
                        <div className="content_box">
                            <div className="square_animation">
                                <div className="square_null square"></div>
                                <div className="square_fill square"></div>
                            </div>
                            <h4 className="card-title ls-1 mbr-fonts-style display-5">Create Wizard</h4>
                            <p className="card-text mb-0 mbr-fonts-style display-4">
                            Please provide essential business need to create a business
                                    wizard
                            </p>                            
                        </div>                        
                        <div className="row">            
                                        <div className="col-12 col-lg-12 card">
                                            <div className="mbr-form form-wrapper" data-form-type="formoid">
                                            <Formik  enableReinitialize
                                            initialValues={wizard!}   
            //initialValues={{ name: '', description: '', title: '',seq:1, status: '',wizardStatus:'',wizardType:'', error: null}}
            onSubmit={(values, { setErrors }) =>
            {
                setWizard(values);
                //wizardStore.register(values).then(()=>setResult(true));
                //.catch(error => setErrors({ error: error }))
                standardConfigurationStore.setWizardRegistry();
                standardConfigurationStore.save();
                 setResult(true);
            }
            }
            // validationSchema={Yup.object({
            //     email: Yup.string().required(),
            //     mobile: Yup.string().required(),
            //     name: Yup.string().required(),
            //     type:Yup.string().required(),
            //     contactmode:Yup.string().required(),
            //     message: Yup.string().required(),
            // })}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (                
                <Form className="mbr-form form-with-styler form-wrap" 
                onSubmit={handleSubmit} 
                autoComplete='off'>
                    <div className="dragArea row">
                    <MyTextInput placeholder="Name" name='name' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                    className="form-control display-4"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Name" errorclassname="alert alert-danger col-12" />
                    <MyTextInput placeholder="Wizard Description" name='description' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                    className="form-control display-7"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Wizard Description" errorclassname="alert alert-danger col-12"
                      />
                      <MyTextInput placeholder="Wizard Title" name='title' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="Wizard Title" errorclassname="alert alert-danger col-12" />

                      <MyTextInput placeholder="Display Order" name='seq' type="number"                     
                    className="form-control display-7" divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Display Order" errorclassname="alert alert-danger col-12" min="1"
                     />
                     <MySelectInput options={wizardtype} name='wizardType' placeholder='Wizard Type'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Wizard Type" errorclassname="alert alert-danger col-12"
                     />
                     <MySelectInput options={wizardstatus} name='wizardStatus' placeholder='Wizard Status'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Wizard Status" errorclassname="alert alert-danger col-12"
                     />
                    {/* <MyTextInput placeholder="Mobile" name='mobile' type="tel" 
                    pattern="[0-9]{10}" 
                    className="form-control display-7" divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Mobile" errorclassname="alert alert-danger col-12"
                     /> */}                      
                     {/*<MySelectInput options={serviceCategoryOptions} name='type' placeholder='type'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Service" errorclassname="alert alert-danger col-12"
                     /> */}
                     {/* <MyTextInput placeholder="Message" name='message'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Service" errorclassname="alert alert-danger col-12"
                     /> */}
                     {/* <ErrorMessage name='error' render={() => 
                        <ValidationError errors={errors.error} />} /> */}
                        <div className="col-12 col-md-auto mbr-section-btn">
                        <Button
                        disabled={!isValid || !dirty || isSubmitting} 
                        loading={isSubmitting} 
                        positive content='Submit Wizard' 
                        type="submit" fluid
                        className="btn btn-info display-4"
                    />                            
                        </div>                                               
                    </div>
                    {result && <div data-form-alert="" className="alert alert-success col-12">Information Saved</div>} 
                    
                    {/* <ErrorMessage name='error' render={() => 
                        <ValidationError errors={errors.error} />} /> */}
                    
                </Form>
            )}

        </Formik>
                                                {/* <form action="https://mobirise.eu/" method="POST" className="mbr-form form-with-styler form-wrap" data-form-title="Form Name">                        
                                                    <div className="row">
                                                        <div data-form-alert="" className="alert alert-success col-12">Thanks for filling
                                                            out the form!</div>
                                                        <div data-form-alert-danger="" className="alert alert-danger col-12"> Oops...! some
                                                            problem!
                                                        </div>
                                                    </div>
                                                    <div className="dragArea row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3" data-htmlFor="name">
                                                            <input type="text" name="name" placeholder="Name" data-form-field="Name" className="form-control display-4" />
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3" data-htmlFor="number">
                                                            <input type="tel" name="number" placeholder="Phone" step="1" data-form-field="mobile" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="form-control display-7" />
                                                        </div>
                                                        <div data-htmlFor="select" className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
                                                            <label htmlFor="select" className="form-control-label mbr-fonts-style display-4">Service</label>
                                                            <select name="select" data-form-field="select" className="form-control display-4">
                                                                <option value="Newjoin">Get Started Today</option>
                                                                <option value="Basic Website Development">Basic Website Development</option>
                                                                <option value="Dynamic Website Development">Dynamic Website Development</option>
                                                                <option value="Custom Website Development">Custom Website Development</option>
                                                                <option value="Web Application Development">Web Application Development</option>
                                                                <option value="Logo Design">Logo Design</option>
                                                                <option value="Mobile App Development">Mobile App Development</option>
                                                                <option value="Hotel Management">Hotel Management</option>
                                                                <option value="Restaurant Management">Restaurant Management</option>
                                                                <option value="Store Management">Store Management</option>
                                                                <option value="Office Management">Office Management</option>
                                                                <option value="Dairy Management">Dairy Management</option>
                                                            </select>
                                                        </div>
                                                        <div data-htmlFor="select1" className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
                                                            <label htmlFor="select1" className="form-control-label mbr-fonts-style display-4">How to contact you?</label>
                                                            <select name="select1" data-form-field="select1" className="form-control display-4">
                                                                <option value="Phone">Phone</option>
                                                                <option value="WhatsApp">WhatsApp</option>                                    
                                                            </select>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3" data-htmlFor="textarea">
                                                            <label htmlFor="textarea" className="form-control-label mbr-fonts-style display-4">Message</label>
                                                            <textarea name="textarea" placeholder="Write to us" data-form-field="textarea" className="form-control display-4"></textarea>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
                                                            <div className="form-control-label">
                                                            </div>
                                                            <div data-htmlFor="I agree to the privacy policy" className="form-check">
                                                                <input type="checkbox" value="Yes" name="I agree to the privacy policy" data-form-field="I agree to the privacy policy" className="form-check-input display-4" />
                                                                <label htmlFor="I agree to the privacy policy" className="form-check-label display-4">I agree to the privacy policy</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-auto mbr-section-btn"><button type="submit" className="btn btn-info display-4">Send Message</button></div>
                                                    </div>
                                                </form> */}
                                            </div>
                                        </div>
                                    </div>                    
                    </div>
                </div>
           </div>
           
       <div className="card_item col-12 col-lg-8 col-md-8">
       <section data-bs-version="5.1" className="content9 visualm5 cid-tZqIByRrCi" id="content9-1q">
    <div className="container-fluid">
        <div className="row">            
            <div className="col-12 col-lg-12">
                <div className="text-wrapper">
                    <p className="mbr-text mbr-fonts-style display-5">
                    Ability to setup wizard
                    </p>
                </div>
            </div>            
            <div className="col-lg-12">
                <div id="bootstrap-accordion_38" className="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">                
                <div className="card">
                        <div className="card-header" role="tab" id="headingThree">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse3_38" aria-expanded="false" aria-controls="collapse3">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                How to Manage Child Wizard?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse3_38" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">		
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Add Child Wizard</strong></h5>
						{/* <p className="price mbr-fonts-style display-7">₹5,000 to ₹20,000 INR</p> */}
						<p className="card-text mbr-fonts-style display-7">
                        Ability to create child Wizard
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7" 						
						onClick={()=> {
                            if(wizard!.name !== null && wizard!.name !== undefined 
                                && wizard!.name !== "")                          
                            navigate(`/addwizard/${wizard!.id}`);
                        }}
						>Add {wizard!.name} Child Wizard</Button></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Child Wizard</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Wizard
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7" 
                            onClick={()=>{
                                if(wizard!.name !== null && wizard!.name !== undefined 
                                    && wizard!.name !== "")                                
                                navigate(`/wizardlist/${wizard!.id}`)
                                }}>Manage {wizard!.name} Child Wizard
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_39" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Parameters?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_39" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#collapse4_39" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">		
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Add Parameter</strong></h5>
						{/* <p className="price mbr-fonts-style display-7">₹5,000 to ₹20,000 INR</p> */}
						<p className="card-text mbr-fonts-style display-7">
                        Ability to create Parameter
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7" 						
						onClick={()=> {
                            if(wizard!.name !== null && wizard!.name !== undefined 
                                && wizard!.name !== "")
                            navigate('/addparameter');
                        }}
						>Add {wizard!.name} Parameter</Button></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Parameter</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Parameter
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7"
                            onClick={()=>{
                                if(wizard!.name !== null && wizard!.name !== undefined 
                                    && wizard!.name !== "")                               
                                navigate("/parameterlist")
                                }}>Manage {wizard!.name} Parameters
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                                {/* <ManageActionsControls addactionRoute="/addstandardparameter" addactiontext="Add Parameters" 
                                    addbuttontext="Create Parameter" adddescription="Ability to create parameter"
                                    mangeactionRoute="/standardparameterlist" mangeactiontext="Manage Parameters"
                                    mangebuttontext="Manage" mangedescription="Ability to Add/Manage Parameters"  /> */}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_40" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Action?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_40" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#collapse4_40" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Action</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Action
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7"
                            onClick={()=>{
                                if(wizard!.name !== null && wizard!.name !== undefined 
                                    && wizard!.name !== "")                                
                                navigate("/actions")
                                }}>Manage {wizard!.name} Actions
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                                {/* <ManageActionsControls addactionRoute="/add" addactiontext="Add Action" 
                                    addbuttontext="Create Action" adddescription="Ability to create Action"
                                    mangeactionRoute="/manage" mangeactiontext="Manage Action"
                                    mangebuttontext="Manage" mangedescription="Ability to Add/Manage Action"  /> */}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_41" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Conditions?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_41" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_38">
                         <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
                                <div className="container">		
                                    <>		
                                    <div className="row">		
                                    <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Manage Display Conditions</strong></h5>
                                                    
                                                    <p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Display Condition
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7"
                                                        onClick={()=>{
                                                            if(wizard!.name !== null && wizard!.name !== undefined 
                                                                && wizard!.name !== "")                                                           
                                                            navigate("/displaycondition")
                                                            }}>Manage {wizard!.name} Display Conditions
                                                        </Button></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Manage Disabled Conditions</strong></h5>
                                                    
                                                    <p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Disabled Condition
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7"
                                                        onClick={()=>{
                                                            if(wizard!.name !== null && wizard!.name !== undefined 
                                                                && wizard!.name !== "")
                                                            navigate("/disabledcondition")
                                                            }}>Manage {wizard!.name} Disabled Condition
                                                        </Button></div>
                                                </div>
                                            </div>
                                        </div>                                        			
                                    </div>                                    
                                    </>
                                </div>
                            </section>
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
                                <div className="container">		
                                    <>		
                                    <div className="row">
                                        <div className="item features-without-image col-12 col-md-6 col-lg-6">
                                            <div className="item-wrapper">
                                                <div className="card-box align-left">
                                                    
                                                    <h5 className="card-title mbr-fonts-style display-4"><strong>Manage Required Condition</strong></h5>
                                                    
                                                    <p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Required Condition
                                                    </p>
                                                    <div className="mbr-section-btn mt-auto">
                                                        <Button className="btn btn-lg btn-primary display-7"
                                                        onClick={()=>{
                                                            if(wizard!.name !== null && wizard!.name !== undefined 
                                                                && wizard!.name !== "")
                                                            navigate("/requiredcondition")}}>Manage {wizard!.name} Required Condition
                                                        </Button></div>
                                                </div>
                                            </div>
                                        </div>			
                                    </div>
                                    </>
                                </div>
                            </section>                               
                                {/* <ManageActionsControls addactionRoute="/displaycondition" addactiontext="Add Display Conditions" 
                                    addbuttontext="Create Action" adddescription="Ability to create Display Conditions"
                                    mangeactionRoute="/manage" mangeactiontext="Manage Display Conditions"
                                    mangebuttontext="Manage" mangedescription="Ability to Add/Manage Display Conditions"  /> */}
                         </div>
                        </div>
                    </div>                                    
                </div>
            </div>
        </div>
    </div>
</section>
           </div>
            {/* <div className="card_item col-12 col-lg-4 col-md-4">
                <div className="item-wrapper">
                    <div className="card-box">
                        <div className="square_animation">
                            <div className="square_null square"></div>
                            <div className="square_fill square"></div>
                        </div>
                        <h4 className="card-title ls-1 mbr-fonts-style display-5">Something else</h4>
                        <p className="card-text mb-0 mbr-fonts-style display-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur voluptas reprehenderit sunt, dolorem totam dicta sequi ad.<br/><br/><a href="index.html" className="text-primary">mobirise@company.com</a><br/><br/>350 5th Ave<br/>New York, NY 10118, USA<br/><br/>Call us on <a href="tel:+1-123-456-78-90" className="text-primary">+1 (123) 456 78 90</a><br/></p>
                    </div>
                </div>
           </div> */}
           </div>
    </div>
</section>
    )
})