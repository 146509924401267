import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { Company, Product } from "../models/company";
export default class CompanyProductStore {
    companyproduct: Product = new Product();
    companyproducts:Product[] = [];
    isChild = false;
    constructor() {
        makeAutoObservable(this)
    }
    register = async (product: Product) => {
        try {            
            runInAction(() =>{
                this.companyproduct = product;
                this.companyproducts = [...this.companyproducts,product];
            });
        } 
        catch (error) {
            throw error;
        }
    }
    manage = async (id:any,product: Product) => {
        try {
                       
            runInAction(() => this.companyproduct = this.getCompanyProduct(id)!);            
        } catch (error) {
            throw error;
        }
    }
    // CompanyProductList = async () => {
    //     try {
    //         // const companyUsersDocSnapshot = await firebaseAgent.userService.users();
    //         // companyUsersDocSnapshot.forEach((message) => {
    //         //     this.setCompanyProduct(message.id,message.data() as User)                
    //         //   });         
    //     } catch (error) {
    //         throw error;
    //     }
    // }
    saveCompanyProduct = (product?: Product) => {
        runInAction(() =>{
            if(this.isChild)
            {
                this.companyproduct!.childProducts!.push(product!);                
            }
            else
            {
                this.companyproduct = product!;
                this.companyproducts = [...this.companyproducts,product!];
            }
        });
    }
    setCompanyProduct = (id:any,product: Product) => {
        runInAction(() =>{
            if(this.isChild)
            {
                this.companyproduct!.childProducts!.push(product);                
            }
            else
            {
                this.companyproduct = product;
                this.companyproducts = [...this.companyproducts,product];
            }
        });
        // runInAction(() => {
        //     company.id = id;
        //     this.companies = [...this.companies,company]
        // });
    }    
    private getCompanyProduct = (id: string) => {
        return this.companyproducts.find(c=>c.id === id);
    }
    loadCompanyProduct = async (id: string) => {
        let companyProductObj = this.getCompanyProduct(id);
        if (companyProductObj) {
            runInAction(()=>{
                this.companyproduct = companyProductObj!;
            })            
            return companyProductObj;
        }        
    }
    get companyProductByDate() {
        return this.companyproducts.slice().        
        sort((a, b) =>
            a.actionDate!.getTime() - b.actionDate!.getTime());
    }
    setChildCompanyProduct = (state: boolean) => {
        this.isChild = state;
    }
    get childCompanyProductByDate() {
        return this.companyproduct !== undefined && this.companyproduct !== undefined ? Array.from(this.companyproduct.childProducts!).slice().      
        sort((a, b) =>
            a.actionDate!.getTime() - b.actionDate!.getTime()) : undefined;
    }
}