import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { Company, User } from "../models/company";
export default class CompanyUsersStore {
    companyuser: User = new User();
    companyusers:User[] = [];
    isChild = false;
    constructor() {
        makeAutoObservable(this)
    }    
    register = async (user: User) => {
        try {
            const userDocRef = await firebaseAgent.userService.register(user);
            const userDocSnapshot = await getDoc(userDocRef);
            runInAction(() => this.companyuser = userDocSnapshot.data() as User);            
        } catch (error) {
            throw error;
        }
    }
    manage = async (id:any,companyuser: User) => {
        try {
            const companyuserDocSnapshot = await firebaseAgent.userService.manage(id,companyuser);            
            runInAction(() => this.companyuser = companyuserDocSnapshot.data() as User);            
        } catch (error) {
            throw error;
        }
    }
    CompanyUsersList = async () => {
        try {
            const companyUsersDocSnapshot = await firebaseAgent.userService.users();
            companyUsersDocSnapshot.forEach((message) => {
                this.setCompanyUser(message.id,message.data() as User)                
              });         
        } catch (error) {
            throw error;
        }
    }
    saveCompanyUser = (companyuser?: User) => {
        runInAction(() =>{
            if(this.isChild)
            {
                this.companyuser!.childUsers!.push(companyuser!);                
            }
            else
            {
                this.companyuser = companyuser!;
                this.companyusers = [...this.companyusers,companyuser!];
            }
        });
    }
    setCompanyUser = (id:any,companyuser: User) => {
        runInAction(() =>{
            if(this.isChild)
            {
                this.companyuser!.childUsers!.push(companyuser);                
            }
            else
            {
                this.companyuser = companyuser;
                this.companyusers = [...this.companyusers,companyuser];
            }
        });
        // runInAction(() => {
        //     company.id = id;
        //     this.companies = [...this.companies,company]
        // });
    }    
    private getCompanyUser = (id: string) => {
        return this.companyusers.find(c=>c.id === id);
    }
    loadCompanyUser = async (id: string) => {
        let companyUserObj = this.getCompanyUser(id);
        if (companyUserObj) {
            this.companyuser = companyUserObj;
            return companyUserObj;
        }        
    }
    get companyUserByDate() {
        return this.companyusers.slice().        
        sort((a, b) =>
            a.actionDate!.getTime() - b.actionDate!.getTime());
    }
    setChildCompanyUsers = (state: boolean) => {
        this.isChild = state;
    }
    get childCompanyUserByDate() {
        return this.companyuser !== undefined && this.companyuser?.childUsers !== undefined ? Array.from(this.companyuser.childUsers!).slice().      
        sort((a, b) =>
            a.actionDate!.getTime() - b.actionDate!.getTime()) : undefined;
    }
}