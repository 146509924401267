import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
export default observer(function ResponsiveTrendyFooterPage() {      
  const { commonStore,modalStore } = useStore();
  const currentyear = new Date().getFullYear();
    return (
        <>
        <section data-bs-version="5.1" className="contact2 algom5 cid-tbW4fstY7Z" id="acontact2-i">

<div className="container">
    <div className="row">
        <div className="col-12 main-col">
            <div className="content-container">
                <div className="col-12 text-container">
                    <div className="text-wrap">
                        <h1 className="mbr-section-title align-center mbr-fonts-style display-2">
                            Would you like to write to us ?
                        </h1>
                        <p className="mbr-text align-center mbr-fonts-style display-4">
                            Check out the media resources below or contact <a href="mailto:support@trendydashboard.com" className="text-black">support@trendydashboard.com</a>.
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<section data-bs-version="5.1" className="footer2 algom5 cid-tbW3sbhq1K" id="afooter2-f">
    <div className="container">
        <div className="media-container-row align-center mbr-white">
            <div className="col-12">
                <p className="mbr-text mb-0 mbr-fonts-style display-4">
                    © Copyright {currentyear} Trendy Dashboard - All Rights Reserved
                </p>
            </div>
        </div>
    </div>
</section>
        </>    
    )
})