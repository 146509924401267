import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import { Action } from "../models/action";
export default class ActionStore {
    action: Action = new Action();    
    actions:Action[] = [];
    constructor() {
        makeAutoObservable(this)
    }
    get actionByDate() {
        return this.actions.sort((a, b) =>
            a.actionDate!.getTime() - b.actionDate!.getTime());
    }
    get groupedAction() {
        return Object.entries(
            this.actionByDate.reduce((parameters, parameter) => {
                const date = parameter.actionDate!.toISOString().split('T')[0];
                parameters[date] = parameters[date] ? [...parameters[date], parameter] : [parameter];
                return parameters;
            }, {} as { [key: string]: Action[] })
        )
    }
    setAction = (actionObj: Action) => {
        const user = store.userStore.user;
        // if (user) {
        //     activity.isGoing = activity.attendees!.some(
        //         a => a.username === user.username
        //     );
        //     activity.isHost = activity.hostUsername === user.username;
        //     activity.host = activity.attendees?.find(x => x.username === activity.hostUsername);
        // }
        // activity.date = new Date(activity.date!);
        if(!actionObj.id)
        {
            actionObj.id = uuid();            
        }
        actionObj.actionDate = new Date();
        actionObj.actionUser = user!.name;
        runInAction(() =>{
            this.action = actionObj;
            this.actions = [...this.actions,actionObj];
        });
    }
    private getAction = (id: string) => {
        return this.actions.find(a=>a.id === id);
    }
    loadAction = async (id: string) => {
        let actionObj = this.getAction(id);
        if (actionObj) {
            this.action = actionObj;
            return actionObj;
        }        
    }
    clearAction = ()=>{
        this.action = new Action();
        this.actions = [];
    }
    setWizardAction = async ()=>{
        try {
            runInAction(() => {               
                store.wizardStore.wizard!.actions = this.actionByDate;
            });
        } catch (error) {
            throw error;
        }
    }
}