import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header, Label } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default observer(function DashboardPage() {
    const { userStore,commonStore,wizardStore } = useStore();
	const navigate = useNavigate();
	useEffect(() => {
		if (commonStore.token) {      
		 userStore.getUser().finally(() => commonStore.setAppLoaded(true))		 
		} else {
		  commonStore.setAppLoaded(true);
		}
	  }, [])
    return (
        <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew" id="trendydashboardservices">
	
	<div className="container">
		{/* <div className="row mb-5 justify-content-center">
			<div className="col-12 mb-0 content-head">
				<h3 className="mbr-section-title mbr-fonts-style align-center display-2">
					<strong>Our Solutions</strong>
				</h3>
				
			</div>
		</div> */}
		<>
		<div className="row">
		<div className="col-md-auto col mbr-section-btn">
			<button type="button" className="btn btn-secondary display-5" 
			onClick={()=> {
				wizardStore.setChildWizard(false);
				navigate('/addwizard');
			}}>
                                <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                                Add Wizard</button></div>
		</div>
		<div className="row">		
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Basic Website Development</strong></h5>
						<p className="price mbr-fonts-style display-7">₹5,000 to ₹20,000 INR</p>
						<p className="card-text mbr-fonts-style display-7">
							A simple website with static content and a few pages
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Dynamic Website Development</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹20,000 to ₹50,000 INR
						</p>
						<p className="card-text mbr-fonts-style display-7">Features like content management systems (CMS) for easy content updates
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Custom Website Development</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹50,000 to ₹5,00,000 INR (or more, depending on complexity)
						</p>
						<p className="card-text mbr-fonts-style display-7">
							Tailored to specific business needs with custom features and design.
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Web Application Development</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹1,00,000 to ₹10,00,000 INR (or more, depending on complexity)
						</p>
						<p className="card-text mbr-fonts-style display-7">
							More complex websites with advanced functionality and interactivity.
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div><div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Logo Design</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹500 to ₹2,000 INR
						</p>
						<p className="card-text mbr-fonts-style display-7">
							Managing and executing social media strategies to increase brand awareness, engagement, and reach the target audience.
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div><div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Mobile App Development</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹50,000 to ₹20,00,000 INR
						</p>
						<p className="card-text mbr-fonts-style display-7">
							More complex mobile app with advanced functionality and interactivity.
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div>
		</div>
		</>
	</div>
</section>
    )
})