import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import { StandardConfigParameter } from "../models/standardconfigparameter";
import { Parameter } from "../models/parameter";
export default class ParameterStore {
    parameter: Parameter = new Parameter();    
    parameters:Parameter[] = [];
    constructor() {
        makeAutoObservable(this)
    }
    get parameterByDate() {
        return this.parameters.sort((a, b) =>
            a.createDate!.getTime() - b.createDate!.getTime());
    }
    get groupedParameter() {
        return Object.entries(
            this.parameterByDate.reduce((parameters, parameter) => {
                const date = parameter.createDate!.toISOString().split('T')[0];
                parameters[date] = parameters[date] ? [...parameters[date], parameter] : [parameter];
                return parameters;
            }, {} as { [key: string]: Parameter[] })
        )
    }
    setParameter = (parm: Parameter) => {
        const user = store.userStore.user;
        // if (user) {
        //     activity.isGoing = activity.attendees!.some(
        //         a => a.username === user.username
        //     );
        //     activity.isHost = activity.hostUsername === user.username;
        //     activity.host = activity.attendees?.find(x => x.username === activity.hostUsername);
        // }
        // activity.date = new Date(activity.date!);
        if(!parm.keyName)
        {
            parm.id = uuid();
            parm.createDate = new Date();
            parm.createBy = user!.name;
        }
        runInAction(() =>{
            this.parameter = parm;
            this.parameters = [...this.parameters,parm];
        });
    }
    private getParameter = (id: string) => {
        return this.parameters.find(p=>p.id === id);
    }
    loadParameter = async (id: string) => {
        let param = this.getParameter(id);
        if (param) {
            this.parameter = param;
            return param;
        }        
    }
    clearParameters = ()=>{
        this.parameter = new Parameter();
        this.parameters = [];
    }
    // setWizardParameter = async ()=>{
    //     try {
    //         runInAction(() => {               
    //             store.wizardStore = this.parameterByDate;
    //         });
    //     } catch (error) {
    //         throw error;
    //     }
    // }
}