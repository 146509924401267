import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header, Label } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import { useNavigate } from "react-router-dom";
import ManageActionsControls from "../admin/controls/ManageActionsControls";

export default observer(function StandardConfigParameterList() {
    const { userStore,standardParameterStore } = useStore();
    const {setWizardParameter,
      standardParameterByDate} = standardParameterStore;
	const navigate = useNavigate();
    return (
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">        
        <div className="row justify-content-center">
        <div className="col-md-auto col mbr-section-btn">
        <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>                                
        <span className="mobi-mbri mobi-mbri-left mbr-iconfont mbr-iconfont-btn"></span>
                                Back to Wizard
                                </button>
            <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>
                                <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                                Add Child Parameter</button>
                      <button type="button" className="btn btn-secondary display-5" 
                        onClick={()=> {
                          setWizardParameter();
                        }}>Add to Wizard</button>
                                </div>
        <h4 className="card-title ls-1 mbr-fonts-style display-5">Wizard Parameter List</h4>
        <table className="table item-wrapper">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">key Name</th>
      <th scope="col">key Value</th>
      <th scope="col">Parameter Type</th>
      <th scope="col">Display Order</th>      
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
  {standardParameterByDate.map((parameter, index) => (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{parameter.keyName}</td>
      <td>{parameter.keyValue}</td>
      <td>{parameter.parameterType}</td>
      <td>{parameter.seq}</td>
      <td>
      <Button positive content='Manage' 
              type="button" onClick={()=>{
                navigate(`/addparameter/${parameter.id}`)
              }} fluid
              className="btn btn-info display-4"
                                      />
      </td>
    </tr>
))}
  </tbody>
</table>
           </div>
    </div>
</section>   
    )
})