import { db } from "../../firestore-config";
import { collection,getDocs,getDoc,addDoc,updateDoc,deleteDoc,doc} from "firebase/firestore";
import { EnquiryFormValues } from "../models/enquiry";
import { firestore } from "firebase-admin";
import { Wizard } from "../models/wizard";
import { Company, User } from "../models/company";

const enquiryCollectionRef = collection(db,"Enquiry");
const companyCollectionRef = collection(db,"Company");
const wizardCollectionRef = collection(db,"Wizard");
const userCollectionRef = collection(db,"User");
const Enquiry = {
    register: (enquiry: EnquiryFormValues) => {
        return addDoc(enquiryCollectionRef,enquiry)
    }
}
const userService = {
    register: (user:User) =>{
        return addDoc(userCollectionRef,user)
    },
    manage: (id:any,user:User) =>{
    const userinfo = doc(userCollectionRef,"User",id);
    updateDoc(userinfo,{
        user 
    });
    return getDoc(userinfo);
    },
    delete: (id:any)=>{
        const userinfo = doc(userCollectionRef,"User",id);
        deleteDoc(userinfo);
    },
    users:()=>{
        return getDocs(userCollectionRef);
    }
}
const companyService = {
    register: (company:Company) =>{
        return addDoc(companyCollectionRef,company)
    },
    manage: (id:any,company:Company) =>{
    const companyinfo = doc(companyCollectionRef,"Company",id);
    updateDoc(companyinfo,{
        company 
    });
    return getDoc(companyinfo);
    },
    delete: (id:any)=>{
        const companyinfo = doc(companyCollectionRef,"Company",id);
        deleteDoc(companyinfo);
    },
    companies:()=>{
        return getDocs(companyCollectionRef);
    }
}
const WizardService ={
    register: (wizard:Wizard) =>{
        return addDoc(wizardCollectionRef,wizard);
    },
    manage: (id:any,wizard:Wizard) =>{
    const wizardinfo = doc(wizardCollectionRef,"Wizard",id);
    updateDoc(wizardinfo,{
        wizard 
    });
    return getDoc(wizardinfo);
    },
    delete: (id:any)=>{
        const wizardinfo = doc(wizardCollectionRef,"Wizard",id);
        deleteDoc(wizardinfo);
    },
    wizardlist:()=>{
        return getDocs(wizardCollectionRef);
    }
}
const firebaseAgent = {
    Enquiry,
    userService,
    companyService,
    WizardService
}
export default firebaseAgent;