import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import { Button, Header } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import ValidationError from "../errors/ValidationError";
import MySelectInput from "../../app/common/form/MySelectInput";
import { contactMode, serviceCategoryOptions } from "../../app/common/options/categoryOptions";
import { useState } from "react";


export default observer(function EnquiryForm() {
    const { enquiryStore,modalStore } = useStore();
    const [result, setResult] = useState<Boolean>(false);
    return (
       
        <Formik            
            initialValues={{ email: '', mobile: '', name: '',contactmode:'', message: '', error: null,type: '' }}
            onSubmit={(values, { setErrors }) =>
            {   
                enquiryStore.register(values).catch(error => setErrors({ error: error }))
                setResult(true);
            }                
            }              
            validationSchema={Yup.object({
                email: Yup.string().required(),
                mobile: Yup.string().required(),
                name: Yup.string().required(),
                type:Yup.string().required(),
                contactmode:Yup.string().required(),
                message: Yup.string().required(),
            })}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (                
                <Form className="mbr-form form-with-styler form-wrap" 
                onSubmit={handleSubmit} 
                autoComplete='off'>
                    <div className="dragArea row">
                    <MyTextInput placeholder="Name" name='name' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                    className="form-control display-4"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Name" errorclassname="alert alert-danger col-12" />
                    <MyTextInput placeholder="Email" name='email' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                    className="form-control display-7"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Email" errorclassname="alert alert-danger col-12"
                      />                    
                    <MyTextInput placeholder="Mobile" name='mobile' type="tel" 
                    pattern="[0-9]{10}" 
                    className="form-control display-7" divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Mobile" errorclassname="alert alert-danger col-12"
                     />
                     <MySelectInput options={contactMode} name='contactmode' placeholder='Type'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Contact Mode" errorclassname="alert alert-danger col-12"
                     />
                     <MySelectInput options={serviceCategoryOptions} name='type' placeholder='type'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Service" errorclassname="alert alert-danger col-12"
                     />
                     <MyTextInput placeholder="Message" name='message'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Service" errorclassname="alert alert-danger col-12"
                     />
                     <ErrorMessage name='error' render={() => 
                        <ValidationError errors={errors.error} />} />
                        <div className="col-12 col-md-auto mbr-section-btn">
                        <Button
                        disabled={!isValid || !dirty || isSubmitting} 
                        loading={isSubmitting} 
                        positive content='Send Message' 
                        type="submit" fluid
                        className="btn btn-info display-4"
                    />                            
                        </div>                                               
                    </div>
                    {result && <div data-form-alert="" className="alert alert-success col-12">Thanks for filling
                                out the form!</div>} 
                    
                    {/* <ErrorMessage name='error' render={() => 
                        <ValidationError errors={errors.error} />} /> */}
                    
                </Form>
            )}

        </Formik>
    )
})