import { makeAutoObservable, runInAction } from "mobx";
import { Enquiry, EnquiryFormValues } from "../models/enquiry";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
export default class EnquiryStore {
    enquiry: Enquiry | null = null;
    constructor() {
        makeAutoObservable(this)
    }    
    register = async (creds: EnquiryFormValues) => {
        try {
            console.log(creds);
            const enquiryDocRef = await firebaseAgent.Enquiry.register(creds);
            const enquiryDocSnapshot = await getDoc(enquiryDocRef);
            runInAction(() => this.enquiry = enquiryDocSnapshot.data() as Enquiry);
            //router.navigate('/activities');
            //store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }
}