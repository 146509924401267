import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import { ConditionConfig } from "../models/conditionsconfig";
export default class DisabledConditionStore {
    condition: ConditionConfig = new ConditionConfig();    
    conditions:ConditionConfig[] = [];
    constructor() {
        makeAutoObservable(this)
    }
    get conditionByDate() {
        return this.conditions.        
        sort((a, b) =>
            a.updateDate!.getTime() - b.updateDate!.getTime());
    }
    get groupedAction() {
        return Object.entries(
            this.conditionByDate.reduce((conditions, condition) => {
                const date = condition.updateDate!.toISOString().split('T')[0];
                conditions[date] = conditions[date] ? [...conditions[date], condition] : [condition];
                return conditions;
            }, {} as { [key: string]: ConditionConfig[] })
        )
    }    
    setDisabledCondition = (conditionObj: ConditionConfig) => {
        const user = store.userStore.user;
        // if (user) {
        //     activity.isGoing = activity.attendees!.some(
        //         a => a.username === user.username
        //     );
        //     activity.isHost = activity.hostUsername === user.username;
        //     activity.host = activity.attendees?.find(x => x.username === activity.hostUsername);
        // }
        // activity.date = new Date(activity.date!);
        if(!conditionObj.id)
        {
            conditionObj.id = uuid();
            conditionObj.createDate = new Date();
            conditionObj.createBy = user!.name;
        }else
        {
            conditionObj.updateBy = user!.name;
            conditionObj.updateDate = new Date(); 
        }
        runInAction(() =>{
            this.condition = conditionObj;
            this.conditions = [...this.conditions,conditionObj];            
        });
    }
    private getCondition = (id: string) => {
        return this.conditions.find(c=>c.id === id);
    }
    loadCondition = async (id: string) => {
        let conditionObj = this.getCondition(id);
        if (conditionObj) {
            this.condition = conditionObj;
            return conditionObj;
        }        
    }
    clearCondition = ()=>{
        this.condition = new ConditionConfig();
        this.conditions = [];
    }
    setWizardDisabledConditions = async ()=>{
        try {
            runInAction(() => {               
                store.wizardStore.wizard!.disabledConditions = this.conditionByDate;
            });
        } catch (error) {
            throw error;
        }
    }
    // setWizardRequiredConditions = async ()=>{
    //     try {
    //         runInAction(() => {               
    //             store.wizardStore.wizard.requiredConditions = this.conditionByDate;
    //         });
    //     } catch (error) {
    //         throw error;
    //     }
    // }    
}