import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import { StandardConfigParameter } from "../models/standardconfigparameter";
import { StandardConfiguration } from "../models/standardconfiguration";
import { storage } from "../../firestore-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export default class StandardConfigurationStore {
    standardConfiguration: StandardConfiguration = new StandardConfiguration();
    constructor() {
        makeAutoObservable(this)
    }
    setStandardConfiguration = (parm: StandardConfiguration) => {
        const user = store.userStore.user;
        // if (user) {
        //     activity.isGoing = activity.attendees!.some(
        //         a => a.username === user.username
        //     );
        //     activity.isHost = activity.hostUsername === user.username;
        //     activity.host = activity.attendees?.find(x => x.username === activity.hostUsername);
        // }
        // activity.date = new Date(activity.date!);
        // if(!parm.wizardRegistry)
        // {
        //     parm.id = uuid();
        //     parm.createDate = new Date();
        //     parm.createBy = user!.name;
        // }
        runInAction(() =>{
            this.standardConfiguration = parm;
            //this.standardConfigurationRegistry.set(parm, parm);
        });
    }
    setWizardRegistry = () => {
        const user = store.userStore.user;        
        runInAction(() =>{
            this.standardConfiguration.wizards = store.wizardStore.wizards;            
        });
    }
    private getStandardConfiguration = () => {
        return this.standardConfiguration;
    }
    loadStandardConfiguration = async () => {
        let config = this.getStandardConfiguration();
        if (config) {
            this.standardConfiguration = config;
            return config;
        }
    }
    save = async () => {
        try {            
            var jsonString = JSON.stringify(this.standardConfiguration);
            var blob = new Blob([jsonString], {type: "application/json"});            
    //         const storageRef = ref(storage, `files/standard-configuration.json`);
    //         const uploadTask = uploadBytesResumable(storageRef, blob);
    //         uploadTask.on("state_changed",
    //   (snapshot) => {
    //     const progress =
    //       Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //     console.log(progress);
    //   },
    //   (error) => {
    //     alert(error);
    //   },
    //   () => {
    //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //       console.log(downloadURL);
    //     });
    //   }
    // );
        } catch (error) {
            throw error;
        }
    }
}