import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import { CustomControl } from "../models/customcontrols";
export default class CustomControlStore {
    customControl: CustomControl = new CustomControl();    
    customControls:CustomControl[] = [];
    constructor() {
        makeAutoObservable(this)
    }    
    setCustomControl = (custom: CustomControl) => {
        const user = store.userStore.user;
        // if (user) {
        //     activity.isGoing = activity.attendees!.some(
        //         a => a.username === user.username
        //     );
        //     activity.isHost = activity.hostUsername === user.username;
        //     activity.host = activity.attendees?.find(x => x.username === activity.hostUsername);
        // }
        // activity.date = new Date(activity.date!);
        if(!custom.id)
        {
            custom.id = uuid();
            custom.createDate = new Date();
            custom.createBy = user!.name;
        }
        runInAction(() =>{
            this.customControl = custom;
            this.customControls = [...this.customControls,custom];
        });
    }
    get customControlByDate() {
        return this.customControls.sort((a, b) =>
            a.createDate!.getTime() - b.createDate!.getTime());
    }
    get groupedstandardParameter() {
        return Object.entries(
            this.customControlByDate.reduce((customcontrols, customcontrol) => {
                const date = customcontrol.createDate!.toISOString().split('T')[0];
                customcontrols[date] = customcontrols[date] ? [...customcontrols[date], customcontrol] : [customcontrol];
                return customcontrols;
            }, {} as { [key: string]: CustomControl[] })
        )
    }
    private getCustomControl = (id: string) => {
        return this.customControls.find(c=>c.id === id);
    }
    loadCustomControl = async (id: string) => {
        let custom = this.getCustomControl(id);
        if (custom) {
            this.customControl = custom;
            return custom;
        }
    }
    clearCustomControl = ()=>{
        this.customControl = new CustomControl();
        this.customControls = [];
    }
    setWizardParameterCustomControl = async ()=>{
        try {
            runInAction(() => {               
                store.standardParameterStore.
                standardParameter.childControls = 
                this.customControlByDate;
            });
        } catch (error) {
            throw error;
        }
    }
}