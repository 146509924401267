export const categoryOptions = [
    {text: 'Drinks', value: 'drinks'},
    {text: 'Culture', value: 'culture'},
    {text: 'Film', value: 'film'},
    {text: 'Food', value: 'food'},
    {text: 'Music', value: 'music'},
    {text: 'Travel', value: 'travel'},
]

export const serviceCategoryOptions = [
    {text: 'Get Started Today', value: 'Get Started Today'},
    {text: 'Basic Website Development', value: 'Basic Website Development'},
    {text: 'Dynamic Website Development', value: 'Dynamic Website Development'},
    {text: 'Custom Website Development', value: 'Custom Website Development'},
    {text: 'Web Application Development', value: 'Web Application Development'},
    {text: 'Logo Design', value: 'Logo Design'},
    {text: 'Mobile App Development', value: 'Mobile App Development'},
    {text: 'Hotel Management', value: 'Hotel Management'},
    {text: 'Restaurant Management', value: 'Restaurant Management'},
    {text: 'Store Management', value: 'Store Management'},
    {text: 'Office Management', value: 'Office Management'},
    {text: 'Dairy Management', value: 'Dairy Management'}
]
export const contactMode = [
    {text: 'Phone', value: 'Phone'},
    {text: 'WhatsApp', value: 'WhatsApp'}
]
export const wizardtype = [
    {text: 'Module', value: 'Module'},
    {text: 'Workflow', value: 'Workflow'},
    {text: 'Workflow Step', value: 'Workflow Step'},
    {text: 'Data', value: 'Data'},
    {text: 'Page', value: 'Page'},
    {text: 'Section', value: 'Section'}    
]
export const wizardstatus = [
    {text: 'New', value: 'New'},
    {text: 'Complete', value: 'Complete'},
    {text: 'Promote', value: 'Promote'}
]
export const parametertype = [
    {text: 'Module', value: 'Module'},
    {text: 'Workflow', value: 'Workflow'},
    {text: 'Workflow Step', value: 'Workflow Step'},
    {text: 'Data', value: 'Data'},
    {text: 'Page', value: 'Page'},
    {text: 'Section', value: 'Section'},
    {text: 'Wizard', value: 'Wizard'},
    {text: 'Transaction', value: 'Transaction'}
]
export const operationtype = [
    {text: 'NotNull', value: 'NotNull'},
    {text: 'EqualsTo', value: 'EqualsTo'},
    {text: 'NotEqualsTo', value: 'NotEqualsTo'},
    {text: 'Count', value: 'Count'},
    {text: 'GreaterThanEqualsTo', value: 'GreaterThanEqualsTo'},
    {text: 'LessThanEqualsTo', value: 'LessThanEqualsTo'}    
]
export const conditiontype = [
    {text: 'And', value: 'And'},
    {text: 'Or', value: 'Or'}  
]
export const controltype = [
    {text: 'SingleLine', value: 'SingleLine'},
    {text: 'SingleSelect', value: 'SingleSelect'},
    {text: 'MultiSelect', value: 'MultiSelect'},
    {text: 'MultiLine', value: 'MultiLine'}
]


export const companytype = [
    {text: 'Properitorship', value: 'Properitorship'},
    {text: 'Private Limited', value: 'PrivateLimited'},
    {text: 'Limited', value: 'Limited'}  
]
export const companystatus = [
    {text: 'Open', value: 'Open'},
    {text: 'ShutDown', value: 'ShutDown'},
    {text: 'Tempary Close', value: 'TemparyClose'}
]

export const plantype = [
    {text: 'Monthly', value: 'Monthly'},
    {text: 'Half Yearly', value: 'Half Yearly'},
    {text: 'Yearly', value: 'Yearly'},
    {text: 'Custom', value: 'Custom'}
]
export const planstatus = [
    {text: 'Pending', value: 'Pending'},
    {text: 'Activated', value: 'Activated'},    
    {text: 'DeActivated', value: 'DeActivated'},
    {text: 'Renew', value: 'Renew'}
]
export const authProvidertype=
[
    {text: 'Email', value: 'Email'},
    {text: 'Gmail', value: 'Gmail'}
]

export const usertype = [
    {text: 'Custom', value: 'Custom'},
    {text: 'Subscribe', value: 'Subscribe'},
    {text: 'Visitor', value: 'Visitor'}  
]
export const userstatus = [
    {text: 'Active', value: 'Active'},
    {text: 'InActive', value: 'InActive'},
    {text: 'Pending', value: 'Pending'},
]
export const categorytype = {
	Company: "Company",
	User: "User"
}
export const accounttype = [
    {text: 'Inventory Management', value: 'inventorymanagement'},
    {text: 'Back Office  Managment', value: 'backofficemanagment'},
    {text: 'Vendor Supplier Managment', value: 'vendorsuppliermanagment'},
    {text: 'Customer Managment', value: 'customermanagment'},
    {text: 'Compliance Managment', value: 'compliancemanagment'},
    {text: 'CRM Managment', value: 'crmmanagment'},
    {text: 'Marketing Promotion Managment', value: 'marketingpromotionmanagment'},
    {text: 'Event Managment', value: 'eventmanagment'},
    {text: 'Accounting Financial Managment', value: 'accountingfinancialmanagment'},
    {text: 'Workflow Managment', value: 'workflowmanagment'},
    {text: 'Coupan Managment', value: 'coupanmanagment'},
    {text: 'Billing Managment', value: 'billingmanagment'},    
]
export const transactiontype = [
    {text: 'Debit', value: 'dr'},
    {text: 'Credit', value: 'cr'}
]
