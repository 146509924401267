import { User } from "firebase/auth";
import { Wizard } from "./wizard";

export interface StandardConfiguration {
    wizards?:Wizard[];
    users?: User[];
}
export class StandardConfiguration implements StandardConfiguration {
    wizards?: Wizard[] = [];
    users?:User[] = [];
    constructor(stdConfig?: StandardConfiguration) {
        if(stdConfig)
        {
            Object.assign(this,stdConfig.wizards);
            Object.assign(this,stdConfig.users);
        }
    }
}