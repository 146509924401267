import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header, Label } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import ManageActionsControls from "../admin/controls/ManageActionsControls";
import { useEffect } from "react";
import { Wizard } from "../../app/models/wizard";

export default observer(function WizardList() {
    const { userStore,wizardStore } = useStore();
	  const navigate = useNavigate();
    const {wizardByDate,isChild,loadWizard,setWizard,
          setChildWizard,childWizardByDate} = wizardStore;
    const { id } = useParams();
    useEffect(() => {
      if (id){
          loadWizard(id).then(wizard => setWizard(new Wizard(wizard)))
          setChildWizard(true);
      }else
          setChildWizard(false);       
  }, [id, loadWizard])
    return (
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">        
        <div className="row justify-content-center">
        <div className="col-md-auto col mbr-section-btn">
        {/* <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>                                
        <span className="mobi-mbri mobi-mbri-left mbr-iconfont mbr-iconfont-btn"></span>
                                Back to Parent Wizard
                                </button> */}
            <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>
                                <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                                Add{isChild ? "Child " : " "} Wizard</button></div>
        <h4 className="card-title ls-1 mbr-fonts-style display-5">Wizard List</h4>
        <table className="table item-wrapper">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Description</th>
      <th scope="col">Title</th>
      <th scope="col">Display Order</th>
      <th scope="col">Wizard Type</th>
      <th scope="col">Wizard Status</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
  {!isChild && wizardByDate.map((wizard, index) => (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{wizard.name}</td>
      <td>{wizard.description}</td>
      <td>{wizard.title}</td>
      <td>{wizard.seq}</td>
      <td>{wizard.wizardType}</td>
      <td>{wizard.wizardStatus}</td>
      <td>
      <Button positive content='Manage' 
              type="button" onClick={()=>{
                navigate(`/addwizard/${wizard.id}`)
              }} fluid
              className="btn btn-info display-4"
                                      />
      </td>
    </tr>
))}
{isChild && childWizardByDate !== undefined && childWizardByDate.map((wizard, index) => (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{wizard.name}</td>
      <td>{wizard.description}</td>
      <td>{wizard.title}</td>
      <td>{wizard.seq}</td>
      <td>{wizard.wizardType}</td>
      <td>{wizard.wizardStatus}</td>
      <td>
      <Button positive content='Manage' 
              type="button" onClick={()=>{
                navigate(`/addwizard/${wizard.id}`)
              }} fluid
              className="btn btn-info display-4"
                                      />
      </td>
    </tr>
))}
  </tbody>
</table>
           </div>
    </div>
</section>   
    )
})