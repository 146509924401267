import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default observer(function ResponsiveTrendyHeaderPage() {      
  const { commonStore,modalStore,userStore,wizardStore,companyStore} = useStore();
  const navigate = useNavigate();
  const {setChildWizard} = wizardStore;
  const {setChildCompany} = companyStore;  
  useEffect(() => {
    if (commonStore.token) {      
     userStore.getUser().finally(() => commonStore.setAppLoaded(true))		 
    } else {
      commonStore.setAppLoaded(true);
    }
  }, [])
    return (
        <section data-bs-version="5.1" className="menu menu1 algom5 cid-tbVVJqh8rW" id="amenu1-0">
    <nav className="navbar navbar-dropdown navbar-expand-lg">
        {
(userStore.isLoggedIn) ? (<div className="container">
<div className="navbar-brand">
    <span className="navbar-logo">
        <a href="#">
            <img src="assets/images/trendydashboardlogo.webp" alt="Trendy Dashboard software company logo" style={{height: "4.9rem"}} />
        </a>
    </span>
    <span className="navbar-caption-wrap"><a className="navbar-caption text-black display-7" href="https://www.trendydashboard.com/">Trendy Dashboard</a></span>
</div>
<div className="icons-menu">
    <a className="iconfont-wrapper" href="https://www.facebook.com/profile.php?id=61554639157101" target="_blank">
        <span className="p-2 mbr-iconfont socicon-facebook socicon"></span>
    </a>
    <a className="iconfont-wrapper" href="#" target="_blank">
        <span className="p-2 mbr-iconfont socicon-twitter socicon"></span>
    </a>
    <a className="iconfont-wrapper" href="https://www.instagram.com/trendydashboardofficial/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank">
        <span className="p-2 mbr-iconfont socicon-instagram socicon"></span>
    </a>
    <h3>Welcome to {userStore.user.name}</h3>
</div>
<button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <div className="hamburger">
        <span></span>
        <span></span>
        <span></span>        
    </div>
</button>
<div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true"><li className="nav-item"><a className="nav-link link text-black text-primary display-4" onClick={()=>{           
           navigate("/dashboard"); 
        }}>Dashboard</a></li>
        <li className="nav-item"><a className="nav-link link text-black text-primary display-4" onClick={()=>{
           setChildWizard(false);
           navigate("/wizardlist"); 
        }}>Wizard</a>
        </li>
        <li className="nav-item"><a className="nav-link link text-black text-primary display-4" onClick={()=>{
           setChildCompany(false);
           navigate("/companylist"); 
        }}>Company</a>
        </li>
        <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="/transactionlist">Transaction</a>
        </li>        
        <li className="nav-item"><a className="nav-link link text-black text-primary display-4" onClick={()=>userStore.logout()}>Sign Out</a>
        </li>
        </ul>
</div>
</div>) :
(<div className="container">
            <div className="navbar-brand">
                <span className="navbar-logo">
                    <a href="#">
                        <img src="assets/images/trendydashboardlogo.webp" alt="Trendy Dashboard software company logo" style={{height: "4.9rem"}} />
                    </a>
                </span>
                <span className="navbar-caption-wrap"><a className="navbar-caption text-black display-7" href="https://www.trendydashboard.com/">Trendy Dashboard</a></span>
            </div>
            <div className="icons-menu">
                <a className="iconfont-wrapper" href="https://www.facebook.com/profile.php?id=61554639157101" target="_blank">
                    <span className="p-2 mbr-iconfont socicon-facebook socicon"></span>
                </a>
                <a className="iconfont-wrapper" href="#" target="_blank">
                    <span className="p-2 mbr-iconfont socicon-twitter socicon"></span>
                </a>
                <a className="iconfont-wrapper" href="https://www.instagram.com/trendydashboardofficial/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank">
                    <span className="p-2 mbr-iconfont socicon-instagram socicon"></span>
                </a>
            </div> 
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <div className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true"><li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="/">Home</a></li>
                    <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="/#trendydashboardjoinus">About Us</a>
                    </li>
                    <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="/#trendydashboardservices">Services</a>
                    </li>
                    <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="/#trendydashboardproducts">Product</a>
                    </li>
                    <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="/#trendydashboardcontactus">Contact Us</a>
                    </li>
                    {/* <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="/login">Login</a>
                    </li> */}
                    </ul>
            </div>
        </div>)
        }        
    </nav>
</section>
    )
})