export interface Action {
    id: string;
    configid: string;
    status: string;    
    actionUser: string;
    actionDate:Date;
    detail?:string;
}
export class Action implements Action {
    constructor(action?:Action)
    {
        if(action)
        {
            Object.assign(this,action);
        }
    }
    id: string = "";
    configid: string="";
    status: string="";
    actionUser: string="";
    actionDate:Date=new Date();
    detail?:string="";
}