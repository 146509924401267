import React from 'react';
import {useField} from "formik";
import {Form, Label} from "semantic-ui-react";

interface Props {
    placeholder: string;
    name: string;
    options: any;
    label?: string;
    className?:string;
    labelclassname?:string;
    errorclassname?:string;
    divclassname?:string;
}

export default function MySelectInput(props: Props) {
    const [field, meta, helpers] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <div className={props.divclassname}>
            {props.label !== null && props.label !== undefined && props.label !== ""  &&             
            <label className={props.labelclassname}>{props.label}</label>
            }
            {/* <Select
                clearable
                options={props.options}
                value={field.value || null}
                onChange={(e, d) => helpers.setValue(d.value)}
                onBlur={() => helpers.setTouched(true)}
                placeholder={props.placeholder}
                className={props.className}
            /> */}
            <select
                // value={field.value}
                //onChange={(d: any) => helpers.setValue(d.value)}
                // onBlur={() => helpers.setTouched(true)}
                // placeholder={props.placeholder}
                // className={props.className}
                {...field} {...props}
                >
                    {props.options.map((entity: { value: string; text: string},key:any)=>(
                        <option key={key} value={entity.value}>{entity.text}</option>
                    )
                    )}
                {/* <option value="Newjoin">Get Started Today</option>
                            <option value="Basic Website Development">Basic Website Development</option>
                            <option value="Dynamic Website Development">Dynamic Website Development</option>
                            <option value="Custom Website Development">Custom Website Development</option>
                            <option value="Web Application Development">Web Application Development</option>
                            <option value="Logo Design">Logo Design</option>
                            <option value="Mobile App Development">Mobile App Development</option>
                            <option value="Hotel Management">Hotel Management</option>
                            <option value="Restaurant Management">Restaurant Management</option>
                            <option value="Store Management">Store Management</option>
                            <option value="Office Management">Office Management</option>
                            <option value="Dairy Management">Dairy Management</option>                 */}
            </select>
            {meta.touched && meta.error ? (
                <div data-form-alert-danger="" className={props.errorclassname}> {meta.error}
                </div>
            ) : null}
            </div>
        </Form.Field>
    )
}