import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import { Plan, Wallet } from "../models/company";
export default class PlanStore {
    categoryType:string = "";
    plan: Plan = new Plan();    
    plans:Plan[] = [];
    constructor() {
        makeAutoObservable(this)
    }
    get planByDate() {
        return this.plans.filter(p=>p.categoryType === this.categoryType).slice().sort((a, b) =>
            a.actionDate!.getTime() - b.actionDate!.getTime());
    }
    get groupedPlan() {
        return Object.entries(
            this.planByDate.reduce((plans, plan) => {
                const date = plan.actionDate!.toISOString().split('T')[0];
                plans[date] = plans[date] ? [...plans[date], plan] : [plan];
                return plans;
            }, {} as { [key: string]: Plan[] })
        )
    }
    setPlan = (planObj: Plan) => {
        const user = store.userStore.user;        
        if(!planObj.id)
        {
            planObj.id = uuid();            
        }
        planObj.actionDate = new Date();
        planObj.actionUser = user!.name;
        planObj.categoryType = this.categoryType;
        runInAction(() =>{
            this.plan = planObj;
            this.plans = [...this.plans,planObj];
        });
    }
    private getPlan = (id: string) => {
        return this.plans.find(a=>a.id === id);
    }
    loadPlan = async (id: string) => {
        let planObj = this.getPlan(id);
        if (planObj) {
            this.plan = planObj;
            return planObj;
        }        
    }
    clearAction = ()=>{
        this.plan = new Plan();
        this.plans = [];
        this.categoryType = "";
    }
    setCompanyPlan = async ()=>{
        try {
            runInAction(() => {               
                store.companyStore.company.companyPlan = this.planByDate;
            });
        } catch (error) {
            throw error;
        }
    }
    setCategoryType(categoryValue:string)
    {
        try
        {
            runInAction(()=>{
              this.categoryType = categoryValue;  
            })
        }catch(error)
        {
            throw error;
        }
    }
}