import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStore } from "../../../app/stores/store";
import { ErrorMessage, Form, Formik } from "formik";
import { useNavigate } from 'react-router-dom';
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from 'yup';
import { Button } from "semantic-ui-react";
import ValidationError from "../../errors/ValidationError";
import { GoogleAuthProvider, signInWithPopup,signInWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../../../firestore-config";

export default observer(function ResponsiveTrendyLoginPage() {
    const { userStore,modalStore,commonStore } = useStore();
    const navigate = useNavigate();    
    if(userStore.isLoggedIn)
        navigate('/dashboard');
    //   const [result, setResult] = useState<Boolean>(false);
     const googleProvider = new GoogleAuthProvider();
     const signInWithGoogle = async () => {
        try {
          const res = await signInWithPopup(auth, googleProvider);
          const user = res.user;
          console.log(user);
          const q = query(collection(db, "users"), where("uid", "==", user.uid));
          const docs = await getDocs(q);
          const newuser = 
            {
                uid: user.uid,
              name: user.displayName,
              token: user.refreshToken,
              email:user.email,
              authProvider: "google",
              email: user.email,
              active: false,
              photoUrl:user.photoURL
            }
          if (docs.docs.length === 0) {            
            await addDoc(collection(db, "users"),newuser);            
          }
          userStore.setUser(newuser);
          //commonStore.setToken(user.refreshToken);
            navigate('/dashboard');
        } catch (err) {
          console.error(err);
          //alert(err.message);
        }
      };
      const logInWithEmailAndPassword = async (email, password) => {
        try {
            const user = await signInWithEmailAndPassword(auth, email, password);
            const q = query(collection(db, "users"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            if (docs.docs.length === 0) {
                //setResult(false);
                return;
            }else
            {
                const existinguser = 
                {
                    uid: user.uid,
                name: user.displayName,
                token: user.refreshToken,
                email:user.email,
                authProvider: "email",
                email: user.email,
                active: false,
                photoUrl:user.photoURL
                }
                userStore.setUser(existinguser);
                navigate('/dashboard');
            }            
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
      };
    return (
        <>
        <section data-bs-version="5.1" className="form01 planm5 cid-tZgkuRgLNA mbr-parallax-background" id="form01-1i">    

    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-lg-6 mbr-form card form-wrapper" data-form-type="formoid">
            <Formik            
            initialValues={{ email: '', password: ''}}
            onSubmit={(values, { setErrors }) =>
            {
                userStore.login(values).catch(error => setErrors({ error: error }))
               // setResult(true);
               //console.log(values);
              // logInWithEmailAndPassword(values.email,values.password);
            }                
            }              
            validationSchema={Yup.object({
                email: Yup.string().required(),
                password: Yup.string().required()
            })}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (                
                <Form className="mbr-form form-with-styler" 
                onSubmit={handleSubmit} 
                autoComplete='off'>
                    <div className="dragArea row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 className="mbr-section-title mbr-fonts-style display-2">
                                Login Page</h2>
                        </div>
                        <MyTextInput placeholder="email" name='email' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                    className="form-control display-7"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="" errorclassname="alert alert-danger col-12" />
                        <MyTextInput type="password" placeholder="Password" name='password' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                    className="form-control display-7"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                    label="" errorclassname="alert alert-danger col-12"
                      />
                     <ErrorMessage name='error' render={() => 
                        <ValidationError errors={errors.error} />} />
                        <div className="col-6 col-md-auto mbr-section-btn">
                        <Button
                        disabled={!isValid || !dirty || isSubmitting} 
                        loading={isSubmitting} 
                        positive //content='Send Message' 
                        type="submit" fluid
                        className="btn btn-secondary display-5"
                        >
                            <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                                Login
                            </Button>                                                        
                        </div>
                    </div>                                       
                    {/* {!result && <div data-form-alert="" className="alert alert-error col-12">Unable to login in</div>}                     */}
                </Form>
                
            )}

        </Formik>
        <div className="col-6 col-md-auto mbr-section-btn">
                        <Button  id="googlesignin" name="googlesignin"                     
                        positive content='Google' 
                        type="button" onClick={signInWithGoogle} fluid
                        className="btn btn-secondary display-5"
                        >
                            <span className="mobi-mbri mobi-mbri-right mbr-iconfont socicon-google socicon"></span>
                                Google
                            </Button>                                                        
                        </div>        
            </div>
        </div>
    </div>
</section>
        </>
    )
})