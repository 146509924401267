import { StringLiteral } from "typescript";
import { Wizard } from "./wizard";
import { ConditionConfig } from "./conditionsconfig";

export interface Company {
    id:string;
    email: string;
    contact: string;
    address1?: string;
    address2?: string;
    state?: string;
    city?: string;
    pincode?: string;
    country?: string;
    name?: string;
    contactPerson: string;
    tinNumber:string;
    gstNumber:string;
    companyType:string;
    status:string;
    createDate:Date;
    createBy:string;
    updateDate:Date;
    updateBy:string;
    companyWallet: Wallet[];
    companyPlan:Plan[];
    childCompany: Company[];
    companyUsers:User[];
    companyProducts:Product[];
}
export class Company implements Company
{
    constructor(company?:Company)
    {
        if(company)
        {
            Object.assign(this,company);
        }
    }
    id:string="";
    email: string="";
    contact: string="";
    address1?: string="";
    address2?: string="";
    state?: string="";
    city?: string="";
    pincode?: string="";
    country?: string="";
    name?: string="";
    contactPerson: string="";
    tinNumber:string="";
    gstNumber:string="";
    companyType:string="";
    status:string="";
    createDate:Date = new Date();
    createBy:string = "";
    updateDate:Date = new Date();
    updateBy:string = "";
    companyWallet: Wallet[] = [];
    companyPlan:Plan[] = [];
    childCompany: Company[] = [];
    companyUsers:User[] = [];
    companyProducts:Product[] = [];
}
export interface Wallet {
    id:string;
    name:string;
    actionDate:Date;
    actionUser:string;
    details:string;
    accountType:string;
    categoryType:string;
    amount:number;
    transactionType:string;
}
export class Wallet implements Wallet
{
    constructor(wallet?:Wallet)
    {
        if(wallet)
        {
            Object.assign(this,wallet);
        }
    }
    id:string = "";
    name:string = "";
    actionDate:Date = new Date();
    actionUser:string="";
    details:string="";
    accountType:string="";
    amount:number=0;
    categoryType: string="";
    transactionType:string = "";
}
export interface Plan {
    id:string;
    actionDate:Date;
    actionUser:string;
    name:string;
    title:string;
    details:string;
    description:string;
    planType:string;
    entries:number;
    renewDate:Date;
    currentStatus:string;
    categoryType:string;
}
export class Plan implements Plan
{
    constructor(wallet?:Wallet)
    {
        if(wallet)
        {
            Object.assign(this,wallet);
        }
    }
    id:string = "";
    name:string = "";
    actionDate:Date = new Date();
    actionUser:string="";
    title:string = "";
    details:string="";
    description:string = "";
    accountType:string="";
    entries:number=0;
    renewDate:Date = new Date();
    currentStatus:string = "";
    categoryType: string="";
}
export interface User {
    id:string;
    uid:string;
    name: string;
    email:string;
    authProvider:string;
    active:boolean;
    token:string;
    photoUrl:string | null;
    userWallet?: Wallet[];
    userPlan?:Plan[];
    childUsers?:User[];
    actionDate:Date;
    actionUser:string;
    userType:string;
    status:string;
}
export class User implements User {
    constructor(user?:User)
    {
        if(user)
        {
            Object.assign(this,user);
        }
    }
    id:string = "";
    uid:string = "";
    name: string = "";
    email:string = "";
    authProvider:string = "";
    active:boolean = false;
    token:string = "";
    photoUrl: string | null = null;
    userWallet?: Wallet[]=[];
    userPlan?:Plan[]=[];
    childUsers?: User[]= [];
    actionDate:Date = new Date();
    actionUser:string="";
    userType: string="";
    status: string="";
}

export interface Product {
    id:string;
    actionDate:Date;
    actionUser:string;
    name:string;
    title:string;
    details:string;
    description:string;
    productType:string;
    entries:number;
    startDate:Date;
    endDate:Date;
    currentStatus:string;
    modules:Module[];
    displayConditions:ConditionConfig[];
    requiredConditions:ConditionConfig[];
    childProducts:Product[];
}
export class Product implements Product
{
    constructor(product?:Product)
    {
        if(product)
        {
            Object.assign(this,product);
        }
    }
    id:string = "";    
    actionDate:Date = new Date();
    actionUser:string="";
    name:string = "";
    title:string = "";
    details:string="";
    description:string = "";
    productType:string="";
    entries:number=0;
    startDate:Date = new Date();
    endDate:Date = new Date();
    currentStatus:string = "";
    modules: Module[] = [];
    displayConditions:ConditionConfig[] = [];
    requiredConditions:ConditionConfig[] = [];
    childProducts:Product[] = [];
}
export interface Module {
    id:string;
    actionDate:Date;
    actionUser:string;
    name:string;
    title:string;
    details:string;
    description:string;
    moduleType:string;
    entries:number;
    startDate:Date;
    endDate:Date;
    currentStatus:string;
    activities:Activity[];
    displayConditions:ConditionConfig[];
    requiredConditions:ConditionConfig[];
    childModules:Module[];
}
export class Module implements Module
{
    constructor(module?:Module)
    {
        if(module)
        {
            Object.assign(this,module);
        }
    }
    id:string = "";    
    actionDate:Date = new Date();
    actionUser:string="";
    name:string = "";
    title:string = "";
    details:string="";
    description:string = "";
    moduleType:string="";
    entries:number=0;
    startDate:Date = new Date();
    endDate:Date = new Date();
    currentStatus:string = "";
    activities:Activity[] = [];
    displayConditions:ConditionConfig[] = [];
    requiredConditions:ConditionConfig[] = [];
    childModules:Module[] = [];
}
export interface Activity {
    id:string;
    actionDate:Date;
    actionUser:string;
    name:string;
    title:string;
    details:string;
    description:string;
    activityType:string;
    entries:number;
    startDate:Date;
    endDate:Date;
    currentStatus:string;
    wizards:Wizard[];
    displayConditions:ConditionConfig[];
    requiredConditions:ConditionConfig[];
    childActivitys:Activity[];
}
export class Activity implements Activity
{
    constructor(module?:Module)
    {
        if(module)
        {
            Object.assign(this,module);
        }
    }
    id:string = "";    
    actionDate:Date = new Date();
    actionUser:string="";
    name:string = "";
    title:string = "";
    details:string="";
    description:string = "";
    activityType:string="";
    entries:number=0;
    startDate:Date = new Date();
    endDate:Date = new Date();
    currentStatus:string = "";
    wizards: Wizard[] = [];
    displayConditions:ConditionConfig[] = [];
    requiredConditions:ConditionConfig[] = [];
    childActivitys:Activity[] = [];
}