import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import ActivityDashboard from "../../features/activities/dashboard/ActivityDashboard";
import ActivityDetails from "../../features/activities/details/ActivityDetails";
import ActivityForm from "../../features/activities/form/ActivityForm";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import TestErrors from "../../features/errors/TestError";
import ProfilePage from "../../features/profiles/ProfilePage";
import App from "../layout/App";
import ResponsiveTrendyLoginPage from "../../features/home/Login/ResponsiveTrendyLoginPage";
import DashboardPage from "../../features/admin/DashboardPage";
import AddWizard from "../../features/wizard/AddWizard";
import ResponsiveTrendyHeaderPage from "../../features/home/Header/ResponsiveTrendyHeaderPage";
import ResponsiveTrendyFooterPage from "../../features/home/Footer/ResponsiveTrendyFooterPage";
import WizardList from "../../features/wizard/WizardList";
import StandardConfigParameterList from "../../features/parameters/StandardConfigParameterList";
import AddStandardConfigParameter from "../../features/parameters/AddStandardConfigParameter";
import DisplayConditionConfigPage from "../../features/conditionconfig/DisplayConditionConfigPage";
import DisabledConditionConfigPage from "../../features/conditionconfig/DisabledConditionConfigPage";
import ManageActionPage from "../../features/action/ManageActionPage";
import RequiredConditionConfigPage from "../../features/conditionconfig/RequiredConditionConfigPage";
import AddCustomControl from "../../features/customcontrol/AddCustomControl";
import CustomControlList from "../../features/customcontrol/CustomControlList";
import ParameterPage from "../../features/parameters/ParameterPage";
import AddCompany from "../../features/company/AddCompany";
import CompanyList from "../../features/company/CompanyList";
import ManageWalletPage from "../../features/wallet/ManageWalletPage";
import ManagePlanPage from "../../features/plan/ManagePlanPage";
import AddCompanyUser from "../../features/company/AddUser";
import CompanyUsersList from "../../features/company/CompanyList";

export const routes: RouteObject[] = [
    // {
    //     path: '/',
    //     element: <App />,
    //     children: [
    //         {element: <RequireAuth />, children: [
    //             // {path: 'activities', element: <ActivityDashboard />},
    //             // {path: 'activities/:id', element: <ActivityDetails />},
    //             // {path: 'createActivity', element: <ActivityForm key='create' />},
    //             // {path: 'manage/:id', element: <ActivityForm key='manage' />},
    //             // {path: 'profiles/:username', element: <ProfilePage />},
    //             // {path: '/projects', element: <TrendyDashboardProjectsPage />},
    //             // {path: '/services', element: <TrendyDashboardServicesPage />},
    //             // {path: '/contactus', element: <TrendyDashboardContactUsPage />},
    //             // {path: '/aboutus', element: <TrendyDashboardAboutUsPage />},
    //             // {path: '/hotelmanagementsystem', element: <TrendyDashboardHotelManagementPage />},
    //             // {path: '/storemanagementsystem', element: <TrendyDashboardStoreManagementPage />},
    //             // {path: '/pharmacymanagementsystem', element: <TrendyDashboardPharmacyManagementPage />},
    //             // {path: '/officemanagementsystem', element: <TrendyDashboardOfficeManagementPage />},
    //             // {path: '/dairymanagementsystem', element: <TrendyDashboardDairyManagementPage />},
    //             {path: 'errors', element: <TestErrors />}
    //         ]},                        
            {path: 'not-found', element: <NotFound />},
    //         {path: 'server-error', element: <ServerError />},
             {path: '*', element: <Navigate replace to='/not-found' />},
    //     ]
    // },
    {
        path:'/',
        element: <App />,
        children: [            
            // {path: 'login', element: <ResponsiveTrendyLoginPage />},
             //{path: 'dashboard', element: <DashboardPage />},
             {path: 'wizardlist', element: <WizardList key="parentwizard" />},
             {path: 'wizardlist/:id', element: <WizardList key="parentchildlist" />},
             {path: 'companylist', element: <CompanyList key="parentcompany" />},
             {path: 'companylist/:id', element: <CompanyList key="parentchildlist" />},
             {path: 'companyuserslist', element: <CompanyUsersList key="parentcompany" />},
             {path: 'companyuserslist/:id', element: <CompanyUsersList key="parentchildlist" />},
             {path: 'companyproductlist', element: <CompanyList key="parentcompany" />},
             {path: 'companyproductlist/:id', element: <CompanyList key="parentchildlist" />},
             {path: 'addcompany', element: <><AddCompany key='create' /></>},
             {path: 'addcompany/:id', element: <><AddCompany key='manage' /></>},  
             {path: 'addcompanyuser', element: <><AddCompanyUser key='create' /></>},
             {path: 'addcompanyuser/:id', element: <><AddCompanyUser key='manage' /></>},            
             {path: 'addwizard', element: <><AddWizard key='create' /></>},
             {path: 'addwizard/:id', element: <><AddWizard key='manage' /></>},             
             {path: 'addparameter', element: <><AddStandardConfigParameter key='create' /></>},
             {path: 'addparameter/:id', element: <><AddStandardConfigParameter key='manage' /></>},
             {path: 'parameterlist', element: <StandardConfigParameterList />},
             {path: 'displaycondition', element: <DisplayConditionConfigPage />},
             {path: 'disabledcondition', element: <DisabledConditionConfigPage />},
             {path: 'requiredcondition', element: <RequiredConditionConfigPage />},
             {path: 'actions', element: <ManageActionPage />},
             {path: 'walletlist', element: <ManageWalletPage />},
             {path: 'planlist', element: <ManagePlanPage />},
            // {path: 'managewizard/:id', element: <><AddWizard key='manage' /></>},
             
             {path: 'addcontrol', element: <><AddCustomControl key='create' /></>},
             {path: 'controllist', element: <CustomControlList />},
             {path: 'parameters/:id', element: <ParameterPage key='manage' />},
             {path: 'parameters/:id/:type', element: <ParameterPage key='managetype' />},
            // {path: 'addstandardparameter', element: <><AddStandardConfigParameter key='create' /></>},
            // {path: 'managestandardparameter/:id', element: <><AddStandardConfigParameter key='manage' /></>},
            // {path: 'standardparameterlist', element: <><StandardConfigParameterList /></>}
        ]
    },
    // {
    //     path:'/',
    //     element: <>
    //     <ResponsiveTrendyHeaderPage />
    //     <ResponsiveTrendyHomePage />
    //     <ResponsiveTrendyFooterPage />
    //     </>
    // },
    {
        path:'/login',
        element: <><ResponsiveTrendyLoginPage /><ResponsiveTrendyFooterPage /></>
    }
    ,
    {
        path:'/dashboard',
        element: <><ResponsiveTrendyHeaderPage /><DashboardPage /><ResponsiveTrendyFooterPage /></>
    }
    //,
    // {
    //     path:'/addwizard',
    //     element: <AddWizard />
    // }
    //,
    // {
    //     path:'/projects',
    // //     element: <TrendyDashboardProjectsPage />
    // element: <App />
    //  },
    // {
    //     path:'/services',
    //     // element: <TrendyDashboardServicesPage />
    //     element: <App />
    // },
    // {
    //     path:'contactus',
    //     element: <TrendyDashboardContactUsPage />
    //     //element: <App />
    // },
    // {
    //     path:'/aboutus',
    //     // element: <TrendyDashboardAboutUsPage />
    //     element: <App />
    // },
    // {
    //     path:'/hotelmanagementsystem',
    //     // element: <TrendyDashboardHotelManagementPage />
    //      element: <App />
    // },
    // {
    //     path:'/storemanagementsystem',
    //     // element: <TrendyDashboardStoreManagementPage />
    //     element: <App />
    // },
    // {
    //     path:'/pharmacymanagementsystem',
    //     // element: <TrendyDashboardPharmacyManagementPage />
    //     element: <App />
    // },
    // {
    //     path:'/officemanagementsystem',
    //     // element: <TrendyDashboardOfficeManagementPage />
    //     element: <App />
    // },
    // {
    //     path:'/dairymanagementsystem',
    //     // element: <TrendyDashboardDairyManagementPage />
    //     element: <App />
    // }
]

export const router = createBrowserRouter(routes);