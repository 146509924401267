import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { ErrorMessage, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import ValidationError from "../errors/ValidationError";
import { Button, Form } from "semantic-ui-react";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { categorytype, companystatus, companytype, wizardstatus, wizardtype } from "../../app/common/options/categoryOptions";
import { useNavigate, useParams } from "react-router-dom";
import { el } from "date-fns/locale";
import { Company } from "../../app/models/company";

export default observer(function AddCompany() {
    const { userStore,companyStore,walletStore,planStore} = useStore();
    const navigate = useNavigate();
    const [result, setResult] = useState<Boolean>(false);
    const { id } = useParams();
    const {company,setCompany,saveCompany,loadCompany} = companyStore;
    useEffect(() => {
        if (id)
        {
            loadCompany(id).then(companyObj => setCompany(id,companyObj!))                  
        }        
    },[])
    return (        
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="card_item col-12 col-lg-4 col-md-4">
                <div className="item-wrapper">
                    <div className="card-box">
                        <div className="content_box">
                            <div className="square_animation">
                                <div className="square_null square"></div>
                                <div className="square_fill square"></div>
                            </div>
                            <h4 className="card-title ls-1 mbr-fonts-style display-5">Create Company</h4>
                            <p className="card-text mb-0 mbr-fonts-style display-4">
                            Please provide essential business need to create a business
                                    company
                            </p>                            
                        </div>                        
                        <div className="row">            
                                        <div className="col-12 col-lg-12 card">
                                            <div className="mbr-form form-wrapper" data-form-type="formoid">
                                            <Formik  enableReinitialize
                                            initialValues={company!}               
            onSubmit={(values, { setErrors }) =>
            {
                saveCompany(values);                
                 setResult(true);
            }
            }
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (                
                <Form className="mbr-form form-with-styler form-wrap" 
                onSubmit={handleSubmit} 
                autoComplete='off'>
                    <div className="dragArea row">
                    <MyTextInput placeholder="Company Name" name='name' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                    className="form-control display-4"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Company Name" errorclassname="alert alert-danger col-12" />
                    <MyTextInput placeholder="Company Email" name='email' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                    className="form-control display-7"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                    label="Company Email" errorclassname="alert alert-danger col-12"
                      />
                      <MyTextInput placeholder="Contact Detail" name='contact' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="Contact Person" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="Address 1" name='address1' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="Address 1" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="Address 2" name='address2' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="Address 2" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="State" name='state' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="State" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="City" name='city' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="City" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="Pincode" name='pincode' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="Pincode" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="Country" name='country' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="Country" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="Contact Person" name='contactPerson' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="Contact Person" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="TIN Number" name='tinNumber' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="TIN Number" errorclassname="alert alert-danger col-12" />
                      <MyTextInput placeholder="GST Number" name='gstNumber' 
                      divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                      className="form-control display-4"
                      labelclassname="form-control-label mbr-fonts-style display-4"
                      label="GST Number" errorclassname="alert alert-danger col-12" />                      
                     <MySelectInput options={companytype} name='companyType' placeholder='Company Type'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Company Type" errorclassname="alert alert-danger col-12"
                     />
                     <MySelectInput options={companystatus} name='status' placeholder='Company Status'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Company Status" errorclassname="alert alert-danger col-12"
                     />                    
                        <div className="col-12 col-md-auto mbr-section-btn">
                        <Button
                        disabled={!isValid || !dirty || isSubmitting} 
                        loading={isSubmitting} 
                        positive content='Submit Company' 
                        type="submit" fluid
                        className="btn btn-info display-4"
                    />                            
                        </div>                                               
                    </div>
                    {result && <div data-form-alert="" className="alert alert-success col-12">Information Saved</div>}
                </Form>
            )}
        </Formik>
                                            </div>
                                        </div>
                                    </div>                    
                    </div>
                </div>
           </div>
           
       <div className="card_item col-12 col-lg-8 col-md-8">
       <section data-bs-version="5.1" className="content9 visualm5 cid-tZqIByRrCi" id="content9-1q">
    <div className="container-fluid">
        <div className="row">            
            <div className="col-12 col-lg-12">
                <div className="text-wrapper">
                    <p className="mbr-text mbr-fonts-style display-5">
                    Ability to setup company
                    </p>
                </div>
            </div>            
            <div className="col-lg-12">
                <div id="bootstrap-accordion_38" className="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">                
                <div className="card">
                        <div className="card-header" role="tab" id="headingThree">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse3_38" aria-expanded="false" aria-controls="collapse3">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                How to Manage Child Company?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse3_38" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">		
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Add Child Company</strong></h5>						
						<p className="card-text mbr-fonts-style display-7">
                        Ability to create child Company
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7" 						
						onClick={()=> {
                            if(company!.name !== null && company!.name !== undefined 
                                && company!.name !== "")                          
                            navigate(`/addcompany/${company!.id}`);
                        }}
						>Add {company!.name} Child Company</Button></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Child Company</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Company
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7" 
                            onClick={()=>{
                                if(company!.name !== null && company!.name !== undefined 
                                    && company!.name !== "")                                
                                navigate(`/companylist/${company!.id}`)
                                }}>Manage {company!.name} Child Company
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_39" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Users?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_39" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#collapse4_39" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">		
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Add User</strong></h5>						
						<p className="card-text mbr-fonts-style display-7">
                        Ability to create User
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7" 						
						onClick={()=> {
                            if(company!.name !== null && company!.name !== undefined 
                                && company!.name !== "")
                                {
                                    navigate('/addcompanyuser');
                                }
                            
                        }}
						>Add {company!.name} User</Button></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage User</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage User
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7"
                            onClick={()=>{
                                if(company!.name !== null && company!.name !== undefined 
                                    && company!.name !== "")                               
                                navigate("/companyuserslist")
                                }}>Manage {company!.name} Users
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_40" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Plan?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_40" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#collapse4_40" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Plan</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Plan
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7"
                            onClick={()=>{
                                if(company!.name !== null && company!.name !== undefined 
                                    && company!.name !== "")
                                    {
                                        planStore.setCategoryType(categorytype.Company);
                                        navigate("/planlist")
                                    }
                                
                                }}>Manage {company!.name} Plan
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_45" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Wallet?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_45" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#collapse4_45" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Wallet</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Wallet
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7"
                            onClick={()=>{
                                if(company!.name !== null && company!.name !== undefined 
                                    && company!.name !== "")
                                    {
                                        walletStore.setCategoryType(categorytype.Company);
                                        navigate("/walletlist")
                                    }
                                }}>Manage {company!.name} Wallet
                            </Button>
                        </div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                            </div>
                        </div>
                    </div>                                              
                </div>
            </div>
        </div>
    </div>
</section>
           </div>
           </div>
    </div>
</section>
    )
})