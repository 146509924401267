import { ConditionConfig } from "./conditionsconfig";
import { Parameter } from "./parameter";

export interface CustomControl {
    id:string;
    isParameter: boolean;
    placeHolder: string;
    label: string;
    italicLabel: string;
    name: string;
    controlType: string;
    value: string;
    displayOrder:number;
    displayConditions:ConditionConfig[];
    requiredConditions:ConditionConfig[];
    disabledConditions:ConditionConfig[];
    popupPosition:string;
    popupHeader:string;
    popupContent:string;
    accept:string;
    customValue:string;
    paramters:Parameter[];
    childControls:CustomControl[];
    createDate:Date;
    createBy:string;
    updateDate:Date;
    updateBy:string;
}

export class CustomControl implements CustomControl {
    constructor(control?:CustomControl)
    {
        if(control)
        {
            Object.assign(this,control);
        }
    }
    id:string = "";
    isParameter: boolean= false;
    placeHolder: string="";
    label: string="";
    italicLabel: string="";
    name: string="";
    controlType: string="";
    value: string="";
    displayOrder:number= 1;
    displayConditions:ConditionConfig[]=[];
    requiredConditions:ConditionConfig[]=[];
    disabledConditions:ConditionConfig[]=[];
    popupPosition:string="";
    popupHeader:string="";
    popupContent:string="";
    accept:string="";
    customValue:string="";
    paramters:Parameter[]=[];
    childControls:CustomControl[]=[];
    createDate:Date = new Date();
    createBy:string = "";
    updateDate:Date = new Date();
    updateBy:string = "";
}