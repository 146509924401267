import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import { Wallet } from "../models/company";
export default class WalletStore {
    categoryType:string = "";
    wallet: Wallet = new Wallet();    
    wallets:Wallet[] = [];
    constructor() {
        makeAutoObservable(this)
    }
    get walletByDate() {
        return this.wallets.filter(w=>w.categoryType === this.categoryType).slice().sort((a, b) =>
            a.actionDate!.getTime() - b.actionDate!.getTime());
    }
    get groupedWallet() {
        return Object.entries(
            this.walletByDate.reduce((wallets, wallet) => {
                const date = wallet.actionDate!.toISOString().split('T')[0];
                wallets[date] = wallets[date] ? [...wallets[date], wallet] : [wallet];
                return wallets;
            }, {} as { [key: string]: Wallet[] })
        )
    }
    setWallet = (walletObj: Wallet) => {
        const user = store.userStore.user;
        if(!walletObj.id)
        {
            walletObj.id = uuid();            
        }
        walletObj.categoryType = this.categoryType;
        walletObj.actionDate = new Date();
        walletObj.actionUser = user!.name;
        runInAction(() =>{
            this.wallet = walletObj;
            this.wallets = [...this.wallets,walletObj];
        });
    }
    private getWallet = (id: string) => {
        return this.wallets.find(a=>a.id === id);
    }
    loadWallet = async (id: string) => {
        let walletObj = this.getWallet(id);
        if (walletObj) {
            this.wallet = walletObj;
            return walletObj;
        }        
    }
    clearAction = ()=>{
        this.wallet = new Wallet();
        this.wallets = [];
        this.categoryType = "";
    }
    setCompanyWallet = async ()=>{
        try {
            runInAction(() => {               
                store.companyStore.company.companyWallet = this.walletByDate;
            });
        } catch (error) {
            throw error;
        }
    }
    setCategoryType(categoryValue:string)
    {
        try
        {
            runInAction(()=>{
              this.categoryType = categoryValue;  
            })
        }catch(error)
        {
            throw error;
        }
    }
}