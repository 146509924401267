import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header, Label } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import { useNavigate } from "react-router-dom";
import ManageActionsControls from "../admin/controls/ManageActionsControls";

export default observer(function CustomControlList() {
    const { userStore } = useStore();
	const navigate = useNavigate();
    return (
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">        
        <div className="row justify-content-center">
        <div className="col-md-auto col mbr-section-btn">
        <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>                                
        <span className="mobi-mbri mobi-mbri-left mbr-iconfont mbr-iconfont-btn"></span>
                                Back to Parent Wizard
                                </button>
            <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>
                                <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                                Add Child Wizard</button></div>
        <h4 className="card-title ls-1 mbr-fonts-style display-5">Wizard List</h4>
        <table className="table item-wrapper">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Description</th>
      <th scope="col">Title</th>
      <th scope="col">Display Order</th>
      <th scope="col">Wizard Type</th>
      <th scope="col">Wizard Status</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td colSpan={2}>Larry the Bird</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table>
           </div>
    </div>
</section>   
    )
})