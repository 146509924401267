// import { Container } from 'semantic-ui-react';
// import NavBar from './NavBar';
// import { observer } from 'mobx-react-lite';
// import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
// import { useStore } from '../stores/store';
// import { useEffect, useState } from 'react';
// import LoadingComponent from './LoadingComponent';
// import ModalContainer from '../common/modals/ModalContainer';
// import TrendyDashboardContactUsPage from '../../features/home/TrendyDashboardContactUsPage';
// import TrendyDashboardHotelManagementPage from '../../features/home/TrendyDashboardHotelManagementPage';
// import TrendyDashboardAboutUsPage from '../../features/home/TrendyDashboardAboutUsPage';
// import TrendyDashboardStoreManagementPage from '../../features/home/TrendyDashboardStoreManagementPage';
// import TrendyDashboardPharmacyManagementPage from '../../features/home/TrendyDashboardPharmacyManagementPage';
// import TrendyDashboardOfficeManagementPage from '../../features/home/TrendyDashboardOfficeManagementPage';
// import TrendyDashboardDairyManagementPage from '../../features/home/TrendyDashboardDairyManagementPage';
// import TrendyDashboardProjectsPage from '../../features/home/TrendyDashboardProjectsPage';
// import TrendyDashboardServicesPage from '../../features/home/TrendyDashboardServicesPage';
// import WebHomePage from '../../features/home/WebHomePage';
// import HomePage from '../../features/home/HomePage';
// import Navbar from '../common/controls/Navbar';
// import Sidebar from '../common/controls/Sidebar';
// import Content from '../common/controls/Content';
// import ResponsiveTrendyHomePage from '../../features/home/ResponsiveTrendyHomePage';
// import ResponsiveTrendyHeaderPage from '../../features/home/Header/ResponsiveTrendyHeaderPage';
// import ResponsiveTrendyFooterPage from '../../features/home/Footer/ResponsiveTrendyFooterPage';
// import ResponsiveTrendyLoginPage from '../../features/home/Login/ResponsiveTrendyLoginPage';
// import DashboardPage from '../../features/admin/DashboardPage';
// import AddWizard from '../../features/admin/AddWizard';

import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { useStore } from "../stores/store";
import { useEffect, useState } from "react";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import { ToastContainer } from "react-toastify";
import ResponsiveTrendyHeaderPage from "../../features/home/Header/ResponsiveTrendyHeaderPage";
import ResponsiveTrendyHomePage from "../../features/home/ResponsiveTrendyHomePage";
import ResponsiveTrendyLoginPage from "../../features/home/Login/ResponsiveTrendyLoginPage";
import DashboardPage from "../../features/admin/DashboardPage";
import AddWizard from "../../features/wizard/AddWizard";
import ResponsiveTrendyFooterPage from "../../features/home/Footer/ResponsiveTrendyFooterPage";
import { observer } from "mobx-react-lite";
import WizardList from "../../features/wizard/WizardList";

function App() {
  const location = useLocation();
  const { commonStore,userStore } = useStore();  
  useEffect(() => {
    if (commonStore.token) {      
     userStore.getUser().finally(() => commonStore.setAppLoaded(true))
     //commonStore.setAppLoaded()
    } else {
      commonStore.setAppLoaded(true);
    }
  }, [commonStore, userStore])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [toggleBtn, setToggleBtn] = useState(true);
  const toggle = () => setToggleBtn(val => !val);
console.log(location.pathname);
  if (!commonStore.appLoaded) return <LoadingComponent content='Loading app...' />

  return (
    <>    
      <ScrollRestoration />
      <ModalContainer />
      <ToastContainer position='bottom-right' hideProgressBar theme='colored' />      
      <ResponsiveTrendyHeaderPage />
      {/* <NavBar /> */}
      {
      location.pathname === '/' ? (<>      
      <ResponsiveTrendyHomePage />      
      </>):
       //location.pathname === '/login' ? (<ResponsiveTrendyLoginPage /> ) :
       //location.pathname === 'dashboard' ? (<DashboardPage /> ) :
       //location.pathname === 'wizardlist' ? (<WizardList /> ) :
      // location.pathname === '/addwizard' ? (<AddWizard />) :
      // location.pathname === '/projects' ? (<div> <TrendyDashboardProjectsPage /></div>):
      // location.pathname === '/services' ? ( <div><TrendyDashboardServicesPage /></div>):
      // location.pathname === '/contactus' ? ( <div><TrendyDashboardContactUsPage /></div>):
      // location.pathname === '/hotelmanagementsystem' ? (<div> <TrendyDashboardHotelManagementPage /></div>):
      // location.pathname === '/aboutus' ? (<div><TrendyDashboardAboutUsPage /></div>):
      // location.pathname === '/storemanagementsystem' ? (<div><TrendyDashboardStoreManagementPage /></div>):
      // location.pathname === '/pharmacymanagementsystem' ? (<div><TrendyDashboardPharmacyManagementPage /></div>):
      // location.pathname === '/officemanagementsystem' ? (<div><TrendyDashboardOfficeManagementPage /></div>):
      // location.pathname === '/dairymanagementsystem' ? (<div><TrendyDashboardDairyManagementPage /></div>):      
      (
        <Outlet />
      )}
      <ResponsiveTrendyFooterPage />
    </>
  );
}

export default observer(App);
