import { Action } from "./action";
import { ConditionConfig } from "./conditionsconfig";
import { StandardConfigParameter } from "./standardconfigparameter";

export interface Wizard {
    name: string;
    description: string;
    title: string;
    seq: number;
    status:string;    
    wizardStatus:string;
    wizardType:string;
    createDate:Date;
    createBy:string;
    updateDate:Date;
    updateBy:string;
    id:string;
    versionNumber:number;
    childWizard:Wizard[];
    paramters:StandardConfigParameter[];
    actions:Action[];
    displayConditions:ConditionConfig[];
    requiredConditions:ConditionConfig[];
    disabledConditions:ConditionConfig[];
}

export class Wizard implements Wizard
{
    constructor(wizard?:Wizard)
    {
        if(wizard !== null && wizard !== undefined)
            Object.assign(this,wizard);
    }
    name: string = "";
    description: string = "";
    title: string = "";
    seq: number = 1;    
    status:string = "";
    wizardStatus:string = "";
    wizardType: string = "";
    createDate:Date = new Date();
    createBy:string = "";
    updateDate:Date = new Date();
    updateBy:string = "";
    id:string = "";
    versionNumber:number = 1;
    childWizard:Wizard[] = [];
    paramters:StandardConfigParameter[] = [];
    actions: Action[] = [];
    displayConditions:ConditionConfig[] = [];
    requiredConditions:ConditionConfig[] = [];
    disabledConditions:ConditionConfig[] = [];
}