import { makeAutoObservable, reaction, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { Wizard } from "../models/wizard";
import { v4 as uuid } from 'uuid';
import { StandardConfiguration } from "../models/standardconfiguration";
export default class WizardStore {
    wizard: Wizard = new Wizard();
    standardConfiguration:StandardConfiguration = new StandardConfiguration();
    wizards:Wizard[] = [];
    isChild = false;
    constructor() {
        makeAutoObservable(this)
        reaction(()=>{},
            ()=> {this.loadWizardList()}
        )
    }
    register = async (wizard: Wizard) => {
        try {            
            const wizardDocRef = await firebaseAgent.WizardService.register(wizard);
            const wizardDocSnapshot = await getDoc(wizardDocRef);
            runInAction(() => this.wizard = wizardDocSnapshot.data() as Wizard);
        } catch (error) {
            throw error;
        }
    }
    get wizardByDate() {
        return this.wizards.slice().        
        sort((a, b) =>
            a.updateDate!.getTime() - b.updateDate!.getTime());
    }
    get childWizardByDate() {
        return this.wizard !== undefined && this.wizard!.childWizard !== undefined ? 
        Array.from(this.wizard!.childWizard).slice().        
        sort((a, b) =>
            a.updateDate!.getTime() - b.updateDate!.getTime()) : undefined;
    }
    setWizard = (wizard: Wizard) => {
        const user = store.userStore.user;
        // if (user) {
        //     activity.isGoing = activity.attendees!.some(
        //         a => a.username === user.username
        //     );
        //     activity.isHost = activity.hostUsername === user.username;
        //     activity.host = activity.attendees?.find(x => x.username === activity.hostUsername);
        // }
        // activity.date = new Date(activity.date!);
        if(!wizard.id)
        {
            wizard.id = uuid();
            wizard.createDate = new Date();
            wizard.createBy = user!.name;
        }
        runInAction(() =>{
            if(this.isChild)
            {
                this.wizard!.childWizard.push(wizard);                
            }
            else
            {
                this.wizard = wizard;
                this.wizards = [...this.wizards,wizard];
            }
        });
    }
    private getWizard = (id: string) => {
        return this.wizards.find(w=>w.id === id);
    }
    loadWizardList = async () => {        
        try 
        {
            store.standardConfigurationStore.
            loadStandardConfiguration();
            runInAction(() => {
            this.wizards = store.standardConfigurationStore.
            standardConfiguration.wizards!;            
            });
        }
        catch (error) {
            throw error;
        }
    }
    setChildWizard = (state: boolean) => {
        this.isChild = state;
    }
    private getWizardList = (id: string) => {
        return this.wizards;
    }   
    loadWizard = async (id: string) => {
        let wizard = this.getWizard(id);
        if (wizard) {
            this.wizard = wizard;
            return wizard;
        }        
    }
}