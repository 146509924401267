import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header, Label } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import { useNavigate, useParams } from "react-router-dom";
import ManageActionsControls from "../admin/controls/ManageActionsControls";
import { useEffect } from "react";
import { Company } from "../../app/models/company";

export default observer(function CompanyList() {
    const { userStore,companyStore } = useStore();
	  const navigate = useNavigate();
    const {companyByDate,isChild,loadCompany,saveCompany,
        setChildCompany,childCompanyByDate,companies} = companyStore;
    const { id } = useParams();
    useEffect(() => {
      if (id){
        loadCompany(id).then(company => saveCompany(new Company(company)))
          setChildCompany(true);
      }else
          setChildCompany(false);       
  }, [id, loadCompany])
    return (
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">        
        <div className="row justify-content-center">
        <div className="col-md-auto col mbr-section-btn">        
            <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addcompany')}>
                                <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                                Add{isChild ? "Child " : " "} Company</button></div>
        <h4 className="card-title ls-1 mbr-fonts-style display-5">Company List</h4>
        <table className="table item-wrapper">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Contact</th>
      <th scope="col">Contact Person</th>
      <th scope="col">Company Type</th>
      <th scope="col">Company Status</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
  {!isChild && companyByDate.map((company, index) => (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{company.name}</td>
      <td>{company.email}</td>
      <td>{company.contact}</td>
      <td>{company.contactPerson}</td>
      <td>{company.companyType}</td>
      <td>{company.status}</td>
      <td>
      <Button positive content='Manage' 
              type="button" onClick={()=>{
                navigate(`/addcompany/${company.id}`)
              }} fluid
              className="btn btn-info display-4"
                                      />
      </td>
    </tr>
))}
{isChild && childCompanyByDate !== undefined && childCompanyByDate.map((company, index) => (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{company.name}</td>
      <td>{company.email}</td>
      <td>{company.contact}</td>
      <td>{company.contactPerson}</td>
      <td>{company.companyType}</td>
      <td>{company.status}</td>
      <td>
      <Button positive content='Manage' 
              type="button" onClick={()=>{
                navigate(`/addcompany/${company.id}`)
              }} fluid
              className="btn btn-info display-4"
                                      />
      </td>
    </tr>
))}
  </tbody>
</table>
           </div>
    </div>
</section>   
    )
})