import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { v4 as uuid } from 'uuid';
import { StandardConfigParameter } from "../models/standardconfigparameter";
export default class StandardParameterStore {
    standardParameter: StandardConfigParameter = new StandardConfigParameter();    
    standardParameters:StandardConfigParameter[] = [];
    isChild = false;
    constructor() {
        makeAutoObservable(this)
    }
    get standardParameterByDate() {
        return this.standardParameters.sort((a, b) =>
            a.createDate!.getTime() - b.createDate!.getTime());
    }
    get groupedstandardParameter() {
        return Object.entries(
            this.standardParameterByDate.reduce((stdParameters, stdParameter) => {
                const date = stdParameter.createDate!.toISOString().split('T')[0];
                stdParameters[date] = stdParameters[date] ? [...stdParameters[date], stdParameter] : [stdParameter];
                return stdParameters;
            }, {} as { [key: string]: StandardConfigParameter[] })
        )
    }
    setStandardConfigParameter = (parm: StandardConfigParameter) => {
        const user = store.userStore.user;
        // if (user) {
        //     activity.isGoing = activity.attendees!.some(
        //         a => a.username === user.username
        //     );
        //     activity.isHost = activity.hostUsername === user.username;
        //     activity.host = activity.attendees?.find(x => x.username === activity.hostUsername);
        // }
        // activity.date = new Date(activity.date!);
        if(!parm.keyName)
        {
            parm.id = uuid();
            parm.createDate = new Date();
            parm.createBy = user!.name;
        }
        runInAction(() =>{
            this.standardParameter = parm;
            this.standardParameters = [...this.standardParameters,parm];
        });
    }
    private getStandardParameter = (id: string) => {
        return this.standardParameters.find(stdParam=> stdParam.id === id);
    }
    loadStandardConfigParameter = async (id: string) => {
        let param = this.getStandardParameter(id);
        if (param) {
            this.standardParameter = param;
            return param;
        }        
    }
    setChildParameter = (state: boolean) => {
        this.isChild = state;
    }
    clearStandardConfigParameter = ()=>{
        this.standardParameter = new StandardConfigParameter();
        this.standardParameters = [];
    }
    setWizardParameter = async ()=>{
        try {
            runInAction(() => {               
                store.wizardStore.wizard!.paramters = this.standardParameterByDate;
            });
        } catch (error) {
            throw error;
        }
    }
}