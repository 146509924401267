import { makeAutoObservable, runInAction } from "mobx";
import firebaseAgent from "../api/firebaseagent";
import { store } from "./store";
import { router } from "../router/Routes";
import { firestore } from "firebase-admin"
import { getDoc } from "firebase/firestore";
import { Company } from "../models/company";
export default class CompanyStore {
    company: Company = new Company();
    companies:Company[] = [];
    isChild = false;
    constructor() {
        makeAutoObservable(this)
    }    
    register = async (company: Company) => {
        try {
            const companyDocRef = await firebaseAgent.companyService.register(company);
            const companyDocSnapshot = await getDoc(companyDocRef);
            runInAction(() => this.company = companyDocSnapshot.data() as Company);
            //router.navigate('/activities');            
        } catch (error) {
            throw error;
        }
    }
    manage = async (id:any,company: Company) => {
        try {
            const companyDocSnapshot = await firebaseAgent.companyService.manage(id,company);            
            runInAction(() => this.company = companyDocSnapshot.data() as Company);
            //router.navigate('/activities');            
        } catch (error) {
            throw error;
        }
    }
    CompaniesList = async () => {
        try {
            const companyDocSnapshot = await firebaseAgent.companyService.companies();
            companyDocSnapshot.forEach((message) => {
                this.setCompany(message.id,message.data() as Company)                
              });
            //router.navigate('/activities');            
        } catch (error) {
            throw error;
        }
    }
    saveCompany = (company: Company) => {
        runInAction(() =>{
            if(this.isChild)
            {
                this.company!.childCompany.push(company);                
            }
            else
            {
                this.company = company;
                this.companies = [...this.companies,company];
            }
        });
    }
    setCompany = (id:any,company: Company) => {
        runInAction(() =>{
            if(this.isChild)
            {
                this.company!.childCompany.push(company);                
            }
            else
            {
                this.company = company;
                this.companies = [...this.companies,company];
            }
        });
        // runInAction(() => {
        //     company.id = id;
        //     this.companies = [...this.companies,company]
        // });
    }    
    private getCompany = (id: string) => {
        return this.companies.find(c=>c.id === id);
    }
    loadCompany = async (id: string) => {
        let companyObj = this.getCompany(id);
        if (companyObj) {
            this.company = companyObj;
            return companyObj;
        }        
    }
    get companyByDate() {
        return this.companies.slice().        
        sort((a, b) =>
            a.updateDate!.getTime() - b.updateDate!.getTime());
    }
    setChildCompany = (state: boolean) => {
        this.isChild = state;
    }
    get childCompanyByDate() {
        return this.company !== undefined && this.company?.childCompany !== undefined ? Array.from(this.company!.childCompany!).slice().      
        sort((a, b) =>
            a.updateDate!.getTime() - b.updateDate!.getTime()) : undefined;
    }
}