import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { ErrorMessage, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import { Button, Form } from "semantic-ui-react";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { conditiontype, operationtype } from "../../app/common/options/categoryOptions";
import { useNavigate, useParams } from "react-router-dom";

export default observer(function ManageActionPage() {
    const { userStore,actionStore} = useStore();
    const [result, setResult] = useState<Boolean>(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const {loadAction,action,
        setAction,actionByDate,
        setWizardAction} = actionStore;
    useEffect(() => {
        if (id) loadAction(id)
    }, [id, loadAction])
    return (
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">              
        <div className="row justify-content-center">
            <div className="card_item col-12 col-lg-4 col-md-4">
                <div className="item-wrapper">
                    <div className="card-box">
                        <div className="content_box">
                            <div className="square_animation">
                                <div className="square_null square"></div>
                                <div className="square_fill square"></div>
                            </div>
                            <h4 className="card-title ls-1 mbr-fonts-style display-5">Manage Action</h4>
                            <p className="card-text mb-0 mbr-fonts-style display-4">
                            Please provide essential action
                            </p>                            
                        </div>                        
                        <div className="row">            
                                        <div className="col-12 col-lg-12 card">
                                            <div className="mbr-form form-wrapper" data-form-type="formoid">
                    <Formik  enableReinitialize
                                            initialValues={action}
                            onSubmit={(values, { setErrors }) =>
                            {
                                setAction(values);
                                setResult(true);
                            }
                            }
                    >
                        {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (                
                            <Form className="mbr-form form-with-styler form-wrap" 
                            onSubmit={handleSubmit} 
                            autoComplete='off'>
                                <div className="dragArea row">
                                <MyTextInput placeholder="Left Expression" name='leftExpression' 
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                                className="form-control display-4"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Left Expression" errorclassname="alert alert-danger col-12" />
                                <MySelectInput options={operationtype} name='operation' placeholder='Operation'
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                className="form-control display-4"
                                label="Operation" errorclassname="alert alert-danger col-12"
                                />
                                <MyTextInput placeholder="Right Expression" name='rightExpression' 
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                className="form-control display-7"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Right Expression" errorclassname="alert alert-danger col-12"
                                />
                                <MySelectInput options={conditiontype} name='conditionType' placeholder='Condition Type'
                                divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                className="form-control display-4"
                                label="Condition Type" errorclassname="alert alert-danger col-12"
                                />
                                <MyTextInput placeholder="Display Order" name='seq' type="number"                     
                                className="form-control display-7" divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                                labelclassname="form-control-label mbr-fonts-style display-4"
                                label="Display Order" errorclassname="alert alert-danger col-12" min="1"
                                />                                                                
                                    <div className="col-12 col-md-auto mbr-section-btn">
                                    <Button
                                    disabled={!isValid || !dirty || isSubmitting} 
                                    loading={isSubmitting} 
                                    positive content='Submit Condition' 
                                    type="submit" fluid
                                    className="btn btn-info display-4"
                                />                            
                                    </div>                                               
                                </div>
                                {result && <div data-form-alert="" className="alert alert-success col-12">Information Saved</div>} 
                            </Form>
                        )}
                    </Formik>
                                            </div>
                                        </div>
                                    </div>                    
                    </div>
                </div>
           </div>
           
       <div className="card_item col-12 col-lg-8 col-md-8">
       <section data-bs-version="5.1" className="content9 visualm5 cid-tZqIByRrCi" id="content9-1q">
    <div className="container-fluid">
        <div className="row">            
            <div className="col-12 col-lg-12">
            <p className="mbr-text mbr-fonts-style display-5">
                    Ability to manage Disabled Conditions
                    </p>
            </div>            
            <div className="col-lg-12">
            <div className="row justify-content-center">
        <div className="col-md-auto col mbr-section-btn">
        <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>
                                Back to Wizard
                                </button>
        <button type="button" className="btn btn-secondary display-5" 
        onClick={()=> {
            setWizardAction();
        }}>                                
                                Add to Wizard
                                </button>
            {/* <button type="button" className="btn btn-secondary display-5" onClick={()=> navigate('/addwizard')}>                                
                                Add Child Wizard</button> */}
                                </div>        
        <table className="table item-wrapper">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Config Id</th>
      <th scope="col">Detail</th>
      <th scope="col">Status</th>            
      <th scope="col">Action Date</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
  {actionByDate.map((action, index) => (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{action.configid}</td>
      <td>{action.detail}</td>
      <td>{action.status}</td>
      <td>{action.actionDate.toISOString()}</td>      
    </tr>
))}
  </tbody>
</table>
           </div>                
            </div>
        </div>
    </div>
</section>
           </div>            
           </div>
    </div>
</section>
    )
})