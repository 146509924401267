import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyBGZ_E4uRIN0w3dDH1MqKwxxqWg5NpxfM4",
  authDomain: "trendydashboard.firebaseapp.com",
  projectId: "trendydashboard",
  storageBucket: "trendydashboard.appspot.com",
  messagingSenderId: "171955933849",
  appId: "1:171955933849:web:5b866a95bdad80328ede08"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);