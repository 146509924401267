import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { ErrorMessage, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import ValidationError from "../errors/ValidationError";
import { Button, Form } from "semantic-ui-react";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { authProvidertype, categorytype, companystatus, companytype, userstatus, usertype, wizardstatus, wizardtype } from "../../app/common/options/categoryOptions";
import { useNavigate, useParams } from "react-router-dom";
import { el } from "date-fns/locale";

export default observer(function AddCompanyUser() {
    const { userStore,companyUsersStore,companyStore,walletStore,planStore} = useStore();
    const navigate = useNavigate();
    const [result, setResult] = useState<Boolean>(false);
    const { id } = useParams();
    const {companyuser,setCompanyUser,saveCompanyUser,loadCompanyUser} = companyUsersStore;
    useEffect(() => {
        if (id)
        {
            loadCompanyUser(id).then(companyUserObj => setCompanyUser(id,companyUserObj!))                  
        }        
    },[])
    return (        
        <section data-bs-version="5.1" className="form1 cid-tZqFaH5qan" id="form1-1m">    
    <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="card_item col-12 col-lg-4 col-md-4">
                <div className="item-wrapper">
                    <div className="card-box">
                        <div className="content_box">
                            <div className="square_animation">
                                <div className="square_null square"></div>
                                <div className="square_fill square"></div>
                            </div>
                            <h4 className="card-title ls-1 mbr-fonts-style display-5">Create {companyStore.company.name} User</h4>
                            <p className="card-text mb-0 mbr-fonts-style display-4">
                            Please provide essential business need to create a business 
                            {companyStore.company.name} user
                            </p>                            
                        </div>                        
                        <div className="row">            
                                        <div className="col-12 col-lg-12 card">
                                            <div className="mbr-form form-wrapper" data-form-type="formoid">
                                            <Formik  enableReinitialize
                                            initialValues={companyuser!}               
            onSubmit={(values, { setErrors }) =>
            {                
                saveCompanyUser(values);                
                 setResult(true);
            }
            }
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (                
                <Form className="mbr-form form-with-styler form-wrap" 
                onSubmit={handleSubmit} 
                autoComplete='off'>
                    <div className="dragArea row">
                    <MyTextInput placeholder="User Name" name='name' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                    className="form-control display-4"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="User Name" errorclassname="alert alert-danger col-12" />
                    <MyTextInput placeholder="User Email" name='email' 
                    divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3" 
                    className="form-control display-4"
                    labelclassname="form-control-label mbr-fonts-style display-4"
                    label="User Email" errorclassname="alert alert-danger col-12" />
                    <MySelectInput options={authProvidertype} name='authProvider' placeholder='Auth Provider'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="Auth Provider" errorclassname="alert alert-danger col-12"
                     />                                     
                     <MySelectInput options={usertype} name='userType' placeholder='User Type'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="User Type" errorclassname="alert alert-danger col-12"
                     />
                     <MySelectInput options={userstatus} name='status' placeholder='User Status'
                     divclassname="col-lg-12 col-md-12 col-sm-12 form-group mb-3"
                     labelclassname="form-control-label mbr-fonts-style display-4"
                     className="form-control display-4"
                     label="User Status" errorclassname="alert alert-danger col-12"
                     />
                        <div className="col-12 col-md-auto mbr-section-btn">
                        <Button
                        disabled={!isValid || !dirty || isSubmitting} 
                        loading={isSubmitting} 
                        positive content='Submit Company User' 
                        type="submit" fluid
                        className="btn btn-info display-4"
                    />                            
                        </div>                                               
                    </div>
                    {result && <div data-form-alert="" className="alert alert-success col-12">Information Saved</div>}
                </Form>
            )}
        </Formik>
                                            </div>
                                        </div>
                                    </div>                    
                    </div>
                </div>
           </div>
           
       <div className="card_item col-12 col-lg-8 col-md-8">
       <section data-bs-version="5.1" className="content9 visualm5 cid-tZqIByRrCi" id="content9-1q">
    <div className="container-fluid">
        <div className="row">            
            <div className="col-12 col-lg-12">
                <div className="text-wrapper">
                    <p className="mbr-text mbr-fonts-style display-5">
                    Ability to setup {companyStore.company.name} user
                    </p>
                </div>
            </div>            
            <div className="col-lg-12">
                <div id="bootstrap-accordion_38" className="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">                
                <div className="card">
                        <div className="card-header" role="tab" id="headingThree">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse3_38" aria-expanded="false" aria-controls="collapse3">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                How to Manage Child {companyStore.company.name} User?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse3_38" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">		
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Add Child {companyStore.company.name} User</strong></h5>						
						<p className="card-text mbr-fonts-style display-7">
                        Ability to create child Company User
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7" 						
						onClick={()=> {
                            if(companyuser!.name !== null && companyuser!.name !== undefined 
                                && companyuser!.name !== "")
                                {
                                    companyUsersStore.setChildCompanyUsers(true);
                                    navigate(`/addcompanyuser/${companyuser!.id}`);
                                }                         
                            
                        }}
						>Add {companyuser!.name} Child Company User</Button></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Child {companyStore.company.name} User</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Company User
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7" 
                            onClick={()=>{
                                if(companyuser!.name !== null && companyuser!.name !== undefined 
                                    && companyuser!.name !== "")
                                    {
                                        companyUsersStore.setChildCompanyUsers(true);
                                        navigate(`/companyuserslist/${companyuser!.id}`)
                                    }
                                }}>Manage {companyuser!.name} Child Company User
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                            </div>
                        </div>
                    </div>                    
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_40" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Plan?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_40" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#collapse4_40" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Plan</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Plan
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7"
                            onClick={()=>{
                                if(companyuser!.name !== null && companyuser!.name !== undefined 
                                    && companyuser!.name !== "")
                                    {
                                        planStore.setCategoryType(categorytype.User);
                                        navigate("/planlist")
                                    }                                
                                }}>Manage {companyuser!.name} Plan
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingFour">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_45" aria-expanded="false" aria-controls="collapse4">
                                <h4 className="panel-title-edit mbr-fonts-style display-5">
                                    How to Manage Wallet?
                                </h4>
                                <span className="sign mbr-iconfont mobi-mbri-plus mobi-mbri"></span>
                            </a>
                        </div>
                        <div id="collapse4_45" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#collapse4_45" data-bs-parent="#bootstrap-accordion_38">
                            <div className="panel-body">
                            <section data-bs-version="5.1" className="features20 cid-tX6b6a5yew">	
	<div className="container">		
		<>		
		<div className="row">
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Manage Wallet</strong></h5>
						
						<p className="card-text mbr-fonts-style display-7">Ability to Add/Manage Wallet
						</p>
						<div className="mbr-section-btn mt-auto">
                            <Button className="btn btn-lg btn-primary display-7"
                            onClick={()=>{
                                if(companyuser!.name !== null && companyuser!.name !== undefined 
                                    && companyuser!.name !== "")
                                    {
                                        walletStore.setCategoryType(categorytype.User);
                                        navigate("/walletlist")                                        
                                    }                                
                                }}>Manage {companyuser!.name} Wallet
                            </Button></div>
					</div>
				</div>
			</div>			
		</div>
		</>
	</div>
</section>
                            </div>
                        </div>
                    </div>                                              
                </div>
            </div>
        </div>
    </div>
</section>
           </div>
           </div>
    </div>
</section>
    )
})