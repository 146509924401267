// import ActivityStore from "./activityStore";
import {createContext, useContext} from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import ModalStore from "./modalStore";
import ProfileStore from "./profileStore";
import CommentStore from "./commentStore";
import EnquiryStore from "./enquiryStore";
import WizardStore from "./wizardStore";
import CustomControlStore from "./customControlStore";
import StandardParameterStore from "./standardParameterStore";
import ParameterStore from "./parameterStore";
import StandardConfigurationStore from "./standardConfigurationStore";
import ActionStore from "./actionStore";
import DisplayConditionStore from "./displayConditionStore";
import DisabledConditionStore from "./disabledConditionStore";
import RequiredConditionStore from "./requiredConditionStore";
import CompanyStore from "./companyStore";
import WalletStore from "./walletStore";
import PlanStore from "./planStore";
import CompanyUsersStore from "./companyUsersStore";
import CompanyProductStore from "./companyProductStore";

interface Store {
    // activityStore: ActivityStore;
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    profileStore: ProfileStore;
    companyStore:CompanyStore;
    commentStore: CommentStore;
    enquiryStore: EnquiryStore;
    wizardStore:WizardStore;
    standardParameterStore:StandardParameterStore;
    parameterStore:ParameterStore;
    customControlStore:CustomControlStore;
    standardConfigurationStore:StandardConfigurationStore;
    actionStore:ActionStore;
    walletStore:WalletStore;
    planStore:PlanStore;
    displayConditionStore:DisplayConditionStore;
    disabledConditionStore:DisabledConditionStore;
    requiredConditionStore:RequiredConditionStore;
    companyUsersStore:CompanyUsersStore;
    companyProductStore:CompanyProductStore;
}
export const store: Store = {
    // activityStore: new ActivityStore(),
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    profileStore: new ProfileStore(),
    companyStore: new CompanyStore(),
    commentStore: new CommentStore(),
    enquiryStore: new EnquiryStore(),
    wizardStore: new WizardStore(),
    standardParameterStore: new StandardParameterStore(),
    customControlStore:new CustomControlStore(),
    parameterStore: new ParameterStore(),
    standardConfigurationStore:new StandardConfigurationStore(),
    actionStore:new ActionStore(),
    walletStore:new WalletStore(),
    planStore:new PlanStore(),
    displayConditionStore:new DisplayConditionStore(),
    disabledConditionStore:new DisabledConditionStore(),
    requiredConditionStore:new RequiredConditionStore(),
    companyUsersStore:new CompanyUsersStore(),
    companyProductStore:new CompanyProductStore(),
}
export const StoreContext = createContext(store);
export function useStore() {
    return useContext(StoreContext);
}