import { ConditionConfig } from "./conditionsconfig";
import { CustomControl } from "./customcontrols";

export interface StandardConfigParameter {
    id:string;
    keyName: string;
    keyValue: string;
    seq: number;
    parameterType:string;
    displayConditions:ConditionConfig[];
    requiredConditions:ConditionConfig[];
    childControls:CustomControl[];
    childParameters:StandardConfigParameter[];
    createDate:Date;
    createBy:string;
    updateDate:Date;
    updateBy:string;
}
export class StandardConfigParameter implements StandardConfigParameter {
    constructor(param?:StandardConfigParameter)
    {
        if(param)
        {
            Object.assign(this,param);
        }
    }
    id:string = "";
    keyName: string = "";
    keyValue: string="";
    seq: number=1;
    parameterType:string = "";
    createDate:Date = new Date();
    createBy:string = "";
    updateDate:Date = new Date();
    updateBy:string = "";
    displayConditions:ConditionConfig[]=[];
    requiredConditions:ConditionConfig[]=[];
    childControls:CustomControl[]=[];
    childParameters:StandardConfigParameter[]=[];
}